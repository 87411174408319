import * as stylex from '@stylexjs/stylex';

import { LogoSymbol } from '~/components/SVG';

import { Text } from '../Text/Text';
import { styles } from './LoadingScreen.styles';

type Props = {
  children?: string;
};

export const LoadingScreen = ({ children }: Props) => {
  return (
    <div {...stylex.props(styles.fullscreen)}>
      <LogoSymbol styles={styles.logo} />

      {children && (
        <Text color="black" size="xlarge" styles={styles.message} weight="bold">
          {children}
        </Text>
      )}
    </div>
  );
};
