import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../SVG.types';

import { styles } from './Logo.styles';

export const LogoSymbol = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg {...stylex.props(styles.logo, styleOverrides)} viewBox="-150 -100 300 200" xmlns="http://www.w3.org/2000/svg" {...delegated}>
      <polygon points="-150,-100 -150,100 -50,100 -50,0 50,0 50,100 150,100 150,-100" />
    </svg>
  );
};
