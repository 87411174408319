import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { useTooltipContext } from './Tooltip.context';
import { styles } from './TooltipTrigger.styles';

type Props = {
  children: ReactNode;
  /** Set this to `true` if none of the children are focusable. */
  focusable?: boolean;
  styles?: stylex.StyleXStyles;
};

export const TooltipTrigger = ({ children, focusable = false, styles: styleOverrides }: Props) => {
  const { getReferenceProps, refs } = useTooltipContext();

  return (
    <div
      {...getReferenceProps({ tabIndex: focusable ? 0 : undefined })}
      {...stylex.props(styles.trigger, styleOverrides)}
      ref={refs.setReference}
    >
      {children}
    </div>
  );
};
