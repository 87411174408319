import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router';
import { match } from 'ts-pattern';

import type { Invoice } from '~/types/invoice';

import { useClients } from '~/api/clients';
import { useUser } from '~/api/user';
import { BrandDot, CheckboxInput, Tooltip } from '~/components';
import { Icon } from '~/components/SVG';
import { CoDocumentStatus } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { filterBoolean, includes, insertIf } from '~/utils/arrays';
import { qs } from '~/utils/searchParams';

import { getInvoiceStatus, InvoiceStatus } from '../../utils';
import { InvoiceTableAdvantageCell } from './InvoiceTableAdvantageCell';
import { InvoiceTableOptions } from './InvoiceTableOptions';

const columnHelper = createColumnHelper<Invoice>();

export const useInvoicesTableColumns = () => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const { t } = useTranslation(['invoices']);
  const { formatCurrency } = useIntl();

  return useMemo(
    () => [
      // Selector
      columnHelper.display({
        id: ColumnIds.selector,
        header: ({ table }) => (
          // Label is necessary to trigger the click event
          <label className="p-0">
            <CheckboxInput
              checked={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </label>
        ),
        cell: ({ row }) =>
          row.getCanSelect() && (
            <div onClick={(e) => e.stopPropagation()}>
              <label className="p-0 max-lg:relative max-lg:after:block max-lg:after:absolute max-lg:after:-inset-4 max-lg:after:cursor-pointer">
                <CheckboxInput checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
              </label>
            </div>
          ),
        meta: {
          styles: { size: 'max-content' },
          mobileStyles: { size: 'max-content' },
        },
      }),

      // Title & entry numbers
      // Note: old invoices only have a description instead of title
      columnHelper.accessor(({ description, entryNumber, title }) => `${entryNumber} ${title || description}`, {
        id: ColumnIds.invoicesTitle,
        header: t('invoices:overview.columns.entryNumber'),
        cell: ({ row: { original: invoice } }) => {
          const brand = user.brands.find((brand) => brand.id === invoice.brandId);

          return (
            <div className="lg:truncate">
              <strong>{invoice.title || invoice.description}</strong>
              <div className="lg:truncate">
                <span>{invoice.entryNumber}</span>
                {brand && <BrandDot color={brand.color} small />}
              </div>
            </div>
          );
        },
        meta: {
          styles: { size: '3fr', minSize: 125 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Brand (hidden, column filter only)
      columnHelper.accessor(({ brandId }) => brandId ?? 0, {
        id: ColumnIds.invoicesBrand,
        enableGlobalFilter: false,
      }),

      // Client
      columnHelper.accessor(({ clientId }) => clients.find((client) => client.id === clientId)?.name, {
        id: ColumnIds.invoicesClient,
        header: t('invoices:overview.columns.client'),
        cell: ({ row: { original: invoice } }) => {
          const client = clients.find(({ id }) => id === invoice.clientId);

          if (!client) return null;

          return (
            <div className="lg:truncate" onClick={(e) => e.stopPropagation()}>
              <Link
                className="text-dark-gray hover:text-dark-gray hover:underline"
                to={{
                  pathname:
                    client.clientType === 'organisation'
                      ? r(routes.showClient, { clientId: client.id })
                      : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                  search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                }}
              >
                {client.name}
              </Link>
            </div>
          );
        },
        sortUndefined: 'last',
        meta: {
          styles: { size: '2fr', minSize: 125 },
          isResizable: true,
        },
      }),

      // Contact (hidden, global filter only)
      columnHelper.accessor(
        ({ contactId }) => {
          const contact = clients.flatMap(({ contacts }) => contacts).find(({ id }) => id === contactId);
          return contact ? `${contact.firstName} ${contact.lastName}` : '';
        },
        {
          id: ColumnIds.invoicesContact,
        },
      ),

      // Doc date
      columnHelper.accessor(({ docDate }) => dayjs(docDate).format('DD/MM/YYYY'), {
        id: ColumnIds.invoicesDocDate,
        header: t('invoices:overview.columns.date'),
        cell: ({ getValue, row: { original: invoice } }) => {
          const notYetPaid = [InvoiceStatus.SENT, InvoiceStatus.CO_PARTIALLY_PAID, InvoiceStatus.EXPIRED].includes(
            getInvoiceStatus(invoice),
          );
          const daysUntilExpired = dayjs(invoice.paymentDueDate).startOf('day').diff(dayjs().startOf('day'), 'day');

          return (
            <div className="lg:truncate">
              <span>{getValue()}</span>

              {notYetPaid && (
                <div className={classNames('lg:truncate', daysUntilExpired > 0 ? 'text-primary-500' : 'text-error-500')}>
                  <Icon inline name={daysUntilExpired > 0 ? 'HourglassTop' : 'HourglassBottom'} size={16} />
                  <span className="ml-1">
                    {daysUntilExpired > 0
                      ? `${daysUntilExpired} ${t('invoices:overview.columns.days')}`
                      : dayjs(invoice.paymentDueDate).format('DD/MM/YYYY')}
                  </span>
                </div>
              )}
            </div>
          );
        },
        sortingFn: ({ original: a }, { original: b }) => {
          const dateA = dayjs(a.docDate);
          const dateB = dayjs(b.docDate);

          return dateA.isSame(dateB) ? 0 : dateA.isBefore(dateB) ? -1 : 1;
        },
        meta: {
          styles: { size: 'max-content', minSize: 100 },
          isResizable: true,
        },
      }),

      // Doc year (hidden, column filter only)
      columnHelper.accessor(({ docYear }) => docYear, {
        id: ColumnIds.invoicesDocYear,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
      }),

      // Doc quarter (hidden, column filter only)
      columnHelper.accessor(({ docDate, docYear }) => `${docYear} ${dayjs(docDate).quarter()}`, {
        id: ColumnIds.invoicesDocQuarter,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
      }),

      // Status
      columnHelper.accessor((invoice) => getInvoiceStatus(invoice), {
        id: ColumnIds.invoicesStatus,
        header: t('invoices:overview.columns.status.title'),
        cell: ({ getValue, row: { original: invoice } }) => {
          const status = getInvoiceStatus(invoice);

          if (
            !includes(
              [InvoiceStatus.LOCKED_BY_USER, InvoiceStatus.CO_PARTIALLY_PAID, InvoiceStatus.CO_PAID, InvoiceStatus.FC_PAID] as const,
              status,
            )
          ) {
            return (
              <CoDocumentStatus small status={status}>
                {invoice.paymentFollowUp !== null
                  ? t('invoices:overview.columns.status.options.PaymentInFollowUp')
                  : t(`invoices:overview.columns.status.options.${getValue()}`)}
              </CoDocumentStatus>
            );
          }

          return (
            <Tooltip disableSafePolygon>
              <Tooltip.Trigger>
                <div className="w-full truncate">
                  <CoDocumentStatus small status={status}>
                    {t(`invoices:overview.columns.status.options.${getValue()}`)}
                    {status === InvoiceStatus.LOCKED_BY_USER && (
                      <span className="ml-2">
                        <Icon inline name="HelpOutline" size={16} />
                      </span>
                    )}
                  </CoDocumentStatus>
                </div>
              </Tooltip.Trigger>

              <Tooltip.Content>
                {match(status)
                  .with(InvoiceStatus.LOCKED_BY_USER, () => t('invoices:overview.columns.status.lockedByUserTooltip'))
                  .with(InvoiceStatus.CO_PAID, () => dayjs(invoice.paidDate).format('DD/MM/YYYY'))
                  .with(InvoiceStatus.FC_PAID, () => dayjs(invoice.febelfinDate).format('DD/MM/YYYY'))
                  .with(InvoiceStatus.CO_PARTIALLY_PAID, () => (
                    <ol>
                      {invoice.paidAmounts.map((paidAmount, i) => (
                        <li key={i}>{`${dayjs(paidAmount.date).format('DD/MM/YYYY')}: ${formatCurrency(paidAmount.amount)}`}</li>
                      ))}
                    </ol>
                  ))
                  .exhaustive()}
              </Tooltip.Content>
            </Tooltip>
          );
        },
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 120, justify: 'center' },
          mobileStyles: { size: 'max-content', minBreakpoint: 'sm' },
        },
      }),

      // Total
      columnHelper.accessor(({ calculationData }) => calculationData.fcExclVat, {
        id: ColumnIds.invoicesTotal,
        header: t('invoices:overview.columns.total'),
        cell: ({ getValue }) => <span className="lg:truncate">{formatCurrency(getValue())}</span>,
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 100, justify: 'end' },
        },
      }),

      // Advantage
      columnHelper.accessor(
        (invoice) =>
          [InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER, InvoiceStatus.CREDITED].includes(getInvoiceStatus(invoice))
            ? undefined
            : invoice.calculationData.advantageWithCs?.advantage || undefined, // Consider 0 as undefined so it is correctly handled when sorting
        {
          id: ColumnIds.invoicesAdvantage,
          header: t('invoices:overview.columns.saved'),
          cell: ({ getValue, row: { original: invoice } }) => {
            const advantage = getValue();
            const status = getInvoiceStatus(invoice);

            if (!advantage || [InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER, InvoiceStatus.CREDITED].includes(status)) {
              return '-';
            }

            return <InvoiceTableAdvantageCell advantage={advantage} invoice={invoice} />;
          },
          sortUndefined: 'last',
          enableGlobalFilter: false,
          meta: {
            styles: { size: 'max-content', minSize: 100, justify: 'end' },
          },
        },
      ),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: invoice } }) => <InvoiceTableOptions invoice={invoice} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [clients, formatCurrency, t, user],
  );
};

export const useInvoiceSearchParamFilters = () => {
  const [searchParams] = useSearchParams();

  const yearSearchParam = Number(searchParams.get(SearchParamKeys.INVOICE_FILTER_YEAR));
  const quarterSearchParam = searchParams.get(SearchParamKeys.INVOICE_FILTER_QUARTER);
  const statusSearchParam = searchParams.get(SearchParamKeys.INVOICE_FILTER_STATUS);

  const yearFilter = yearSearchParam >= 2014 && !Number.isNaN(yearSearchParam) && Number.isFinite(yearSearchParam) ? yearSearchParam : null;
  const quarterFilter = /\d{4} (1|2|3|4)/.test(quarterSearchParam ?? '') ? quarterSearchParam : null;
  const statusFilter = includes(
    [
      InvoiceStatus.PENDING,
      InvoiceStatus.LOCKED_BY_USER,
      InvoiceStatus.SENT,
      InvoiceStatus.EXPIRED,
      InvoiceStatus.CO_PARTIALLY_PAID,
      InvoiceStatus.CO_PAID,
      InvoiceStatus.FC_PAID,
      InvoiceStatus.CREDITED,
    ] as const,
    statusSearchParam,
  )
    ? statusSearchParam
    : null;

  return useMemo(
    () => ({
      year: yearFilter,
      quarter: quarterFilter,
      status: statusFilter,
    }),
    [quarterFilter, statusFilter, yearFilter],
  );
};

export const useInvoicesTableFilters = (invoices: Invoice[]) => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const searchParamFilters = useInvoiceSearchParamFilters();

  const { t } = useTranslation(['filters', 'invoices']);
  const { compareForSort } = useIntl();

  const clientOptions = useMemo(
    () =>
      [...new Set(invoices.map(({ clientId }) => clientId))]
        .map((clientId) => {
          const clientName = clients.find(({ id }) => id === clientId)?.name ?? '';
          return { label: clientName, value: clientName };
        })
        .sort((a, b) => compareForSort(a.label, b.label)),
    [clients, compareForSort, invoices],
  );

  const brandOptions = useMemo(
    () =>
      [...new Set(invoices.map(({ brandId }) => brandId))]
        .map((brandId) => {
          if (brandId === 0) return { label: user.companyName, value: 0 };

          const brand = user.brands.find((brand) => brand.id === brandId);

          if (!brand) return null;

          return { label: brand.companyName, value: brand.id };
        })
        .filter(filterBoolean)
        .sort((a, b) => compareForSort(a.label, b.label)),
    [compareForSort, invoices, user.brands, user.companyName],
  );

  const docYearOptions = useMemo(
    () =>
      [...new Set([...invoices.map(({ docYear }) => docYear), ...(searchParamFilters.year !== null ? [searchParamFilters.year] : [])])]
        .sort((a, b) => b - a)
        .map((docYear) => ({ label: `${docYear}`, value: docYear })),
    [invoices, searchParamFilters.year],
  );

  const docQuarterOptions = useMemo(
    () =>
      [
        ...new Set([
          ...invoices.map(({ docDate, docYear }) => `${docYear} ${dayjs(docDate).quarter()}`),
          ...(searchParamFilters.quarter !== null ? [searchParamFilters.quarter] : []),
        ]),
      ]
        .map((quarter) => ({ year: +quarter.split(' ')[0], quarter: +quarter.split(' ')[1] }))
        .sort((a, b) => b.year - a.year || b.quarter - a.quarter)
        .map(({ quarter, year }) => ({ label: t('filters:quarter.option', { year, quarter }), value: `${year} ${quarter}` })),

    [invoices, searchParamFilters.quarter, t],
  );

  const statusOptions = useMemo(
    () =>
      [
        ...insertIf(
          invoices.some((invoice) => getInvoiceStatus(invoice) === InvoiceStatus.LOCKED_BY_USER) ||
            searchParamFilters.status === InvoiceStatus.LOCKED_BY_USER,
          InvoiceStatus.LOCKED_BY_USER,
        ),
        InvoiceStatus.PENDING,
        InvoiceStatus.SENT,
        InvoiceStatus.EXPIRED,
        ...insertIf(
          invoices.some((invoice) => getInvoiceStatus(invoice) === InvoiceStatus.CO_PARTIALLY_PAID) ||
            searchParamFilters.status === InvoiceStatus.CO_PARTIALLY_PAID,
          InvoiceStatus.CO_PARTIALLY_PAID,
        ),
        InvoiceStatus.CO_PAID,
        InvoiceStatus.FC_PAID,
        InvoiceStatus.CREDITED,
      ].map((status) => ({ label: t(`invoices:overview.columns.status.options.${status}`), value: status })),
    [invoices, searchParamFilters.status, t],
  );

  return useMemo(
    () => ({
      clients: clientOptions,
      brands: brandOptions,
      docYears: docYearOptions,
      docQuarters: docQuarterOptions,
      statuses: statusOptions,
    }),
    [brandOptions, clientOptions, docQuarterOptions, docYearOptions, statusOptions],
  );
};
