import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';

import { useInvoices } from '~/api/invoices';
import { SlotMachine } from '~/components/SVG';
import { Span, Text } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';
import { darkBackgroundThemes } from '~/styles/themes';

import { styles } from './PricingAdvantage.styles';

export const PricingAdvantage = () => {
  const { data: invoices } = useInvoices();

  const paidInvoices = invoices.filter(({ status }) => ['CoPaid', 'FcPaid'].includes(status));
  const paidInvoicesCurrentYear = paidInvoices.filter(({ docDate }) => dayjs(docDate).year() === dayjs().year());
  const paidInvoicesLastYear = paidInvoices.filter(({ docDate }) => dayjs(docDate).year() === dayjs().subtract(1, 'year').year());
  const hasPaidInvoicesLastYear = paidInvoicesLastYear.length > 0;

  const advantageCurrentYear = paidInvoicesCurrentYear.reduce(
    (total, { calculationData: { advantageWithCs } }) => total + (advantageWithCs?.advantage ?? 0),
    0,
  );

  const advantageLastYear = paidInvoicesLastYear.reduce(
    (total, { calculationData: { advantageWithCs } }) => total + (advantageWithCs?.advantage ?? 0),
    0,
  );

  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['pricing']);

  return (
    <section>
      <article {...stylex.props(darkBackgroundThemes, styles.wrapper)}>
        <header {...stylex.props(styles.header)}>
          <Text size="large" weight="medium" wrap="balance">
            <Trans components={{ colored: <Span styles={styles.highlight} /> }} i18nKey="pricing:pricingAdvantage.advantageCurrentYear" />
          </Text>
          <Text size="xlarge" styles={styles.advantage} weight="semiBold">
            {formatCurrency(advantageCurrentYear)}
          </Text>

          {hasPaidInvoicesLastYear && (
            <>
              <Text size="large" weight="medium">
                <Trans components={{ colored: <Span styles={styles.highlight} /> }} i18nKey="pricing:pricingAdvantage.advantageLastYear" />
              </Text>
              <Text size="xlarge" styles={styles.advantage} weight="semiBold">
                {formatCurrency(advantageLastYear)}
              </Text>
            </>
          )}
        </header>

        <div {...stylex.props(styles.disclaimer)}>
          <Text color="muted" size="tiny" wrap="pretty">
            {t('pricing:pricingAdvantage.disclaimer', { count: hasPaidInvoicesLastYear ? 2 : 1 })}
          </Text>
        </div>

        <div {...stylex.props(styles.illustrationWrapper)}>
          <div {...stylex.props(styles.illustration)}>
            <SlotMachine />
          </div>
        </div>
      </article>
    </section>
  );
};
