export const lines = {
  actions: {
    applyDiscount: 'Appliquer la remise',
    removeDiscount: 'Supprimer la remise',
  },
  benefit: 'Avantage',
  creativeWork: {
    header: 'Type de prestation',
    options: {
      '0': 'Prestation non créative',
      '1': 'Prestation créative',
      '2': 'Réalisation créative de programme informatique',
      '3': 'Réutilisation',
      '4': 'En sous-traitance',
      CreativeComputer: 'Prestation créative programme informatique',
      CreativeGeneral: 'Prestation créative',
      CreativeReuse: 'Réutilisation',
      CreativeSubcontracting: 'Sous-traitance',
      NonCreative: 'Prestation non créative',
    },
    placeholder: 'Travail créatif ?',
  },
  description: {
    header: 'Description',
    placeholder: 'Description',
    placeholderProduct: 'Informations complémentaires',
  },
  discount: {
    free: 'Gratuit',
    header: 'Remise',
  },
  discountType: {
    flatRate: '€',
    percentage: '%',
  },
  lineTotal: {
    header: 'Total',
  },
  modal: {
    title: 'Ligne de facture',
  },
  new: {
    invoiceLine: 'Ajouter une ligne',
    photoAlbum: 'Ajouter album photo',
    photoAlbumLine: 'Ajouter une ligne album photo',
    product: 'Ajouter un produit',
    productTooltip:
      '<a href="https://knowledge-creativeshelter-be.translate.goog/producten-wat-zijn-ze-en-hoe-gebruik-je-ze?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">Découvrez les produits ici !</a>',
    quotationLine: 'Ajoute une ligne',
    textLine: 'Ajouter une ligne de texte',
  },
  photoAlbum: {
    deleteModal: {
      description:
        'Êtes-vous sûr de vouloir supprimer la section album photo ? Toutes les lignes de cette section seront également supprimées.',
      title: 'Supprimer album photo',
    },
    description: 'Ajoutez toutes les lignes de facture liées à l’album photo ici.',
    tag: '6 % de TVA',
    tagTooltip: 'Toutes les lignes de la section album photo seront facturées à 6 % de TVA.',
    title: 'Album photo',
  },
  price: {
    header: 'Prix (hors TVA)',
    placeholder: 'Prix',
  },
  productName: {
    label: 'Produit',
    tooltip:
      'Cette ligne est basée sur un produit. Des modifications sont possibles, celles-ci ne sont pas enregistrées dans votre produit, mais uniquement sur votre facture.',
  },
  quantity: {
    header: 'Nombre de',
    placeholder: 'Nombre de',
  },
  subtotal: 'Sous-total',
  textLine: 'Ligne de texte',
  total: 'Total',
  totalVat: 'Total TVA',
  units: {
    characters_one: 'Caractère',
    characters_other: 'Caractères',
    days_one: 'Jour',
    days_other: 'Jours',
    halfDays_one: 'Demi-journée',
    halfDays_other: 'Demi-journées',
    hours_one: 'Heure',
    hours_other: 'Heures',
    kilometers: 'Km',
    none: '—',
    placeholder: 'Sélectionner',
    words_one: 'Mot',
    words_other: 'Mots',
  },
  vatExported: 'Exportation',
  vatPercentage: 'TVA ({{ percentage }}%)',
  vatShifted: 'Autoliquidation',
  vatTooltip:
    'Nous facturons toujours la TVA sur la facture de votre client, quel que soit votre statut en matière de TVA, car Creative Shelter est une entreprise assujettie à la TVA. <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">Pour en savoir plus, cliquez ici !</a>',
};
