import type { CalculationData, Line } from '~/api/common/types';
import type { PlanId } from '~/constants/plans.types';

export type Invoice = {
  allowed: boolean;
  allowsAutomaticApproval: boolean;
  billingPeriod: BillingPeriod;
  billingPeriodEndOfMonth: boolean;
  brandId: number;
  calculationData: CalculationData;
  clientId: number;
  commission: number;
  conditionId: number | null;
  contactId: number;
  createdDate: string;
  credited: boolean;
  creditNoteDate: string | null;
  csNumber: string | null;
  customAgreementId: number | null;
  customConditionId: number | null;
  description: string;
  docDate: string;
  docYear: number;
  draft: boolean;
  entryNumber: string;
  febelfinDate: string | null;
  final?: boolean;
  id: number;
  invoiceLines: Line[];
  legacyCalculationData: {
    copyrightFc21: number;
    copyrightFc6: number;
    netBenefit: number;
    totalFc: number;
    vatCopyrightFc21: number;
    vatCopyrightFc6: number;
    vatFc: number;
    vatPerformanceFc: number;
  };
  lockedByUser: boolean;
  numberingSeriesId: number | null;
  orderReference: string;
  paid: boolean;
  paidAmounts: { amount: number; date: string }[];
  paidDate: string;
  partiallyPaid: boolean;
  paymentDueDate: string;
  paymentFollowUp: PaymentFollowUp[] | null;
  planId: PlanId;
  referralProfit: number;
  remark: string;
  specialConditions: string;
  status: 'Draft' | 'InReview' | 'LockedByUser' | 'Approved' | 'Expired' | 'CoPaid' | 'FcPaid' | 'Credited';
  title: string;
};

export type PaymentFollowUp = {
  id: number;
  remark: {
    causerName: string;
    causerType: 'user' | 'admin';
    message: string;
  } | null;
  timestamp: string;
  type: PaymentFollowUpStatusType;
};

export type PaymentFollowUpStatusType =
  | 'FollowUpStarted'
  | 'FollowUpManualReminder'
  | 'FollowUpContactClientByPhone'
  | 'FollowUpLastManualReminder'
  | 'FollowUpFormalNoticeSent'
  | 'FollowUpInvoiceSentToLawyer'
  | 'FollowUpInvoiceSentToBailiff'
  | 'FollowUpRemark';

// ENUMS

export enum BillingPeriod {
  FIFTEEN = 15,
  THIRTY = 30,
  FORTY_FIVE = 45,
  SIXTY = 60,
}
