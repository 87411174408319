import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';

import type { ColorTheme } from '~/api/user/types';

import { colorThemes } from '~/api/user/constants';
import { brandColorThemes } from '~/styles/themes';

import { styles } from './ColorPicker.styles';

type Props = {
  onSelect: (colorTheme: ColorTheme) => void;
  value: ColorTheme;
};

export const ColorPicker = ({ onSelect, value }: Props) => {
  return (
    <Menu>
      <div {...stylex.props(styles.wrapper)}>
        <MenuButton {...stylex.props(styles.circleButton, styles.mainCircleButton, brandColorThemes[value])}>
          <div {...stylex.props(styles.rotated)}>
            <div {...stylex.props(styles.half, styles.topHalf)} />
            <div {...stylex.props(styles.half, styles.bottomHalf)} />
          </div>
        </MenuButton>
      </div>

      <MenuItems {...stylex.props(styles.itemsContainer)} anchor={{ to: 'bottom', gap: 4 }} modal={false} transition>
        {colorThemes.map((colorTheme) => (
          <MenuItem as={ColorPickerCircleButton} colorTheme={colorTheme} key={colorTheme} onClick={() => onSelect(colorTheme)} />
        ))}
      </MenuItems>
    </Menu>
  );
};

type CircleButtonProps = {
  colorTheme: ColorTheme;
  onClick: () => void;
};

export const ColorPickerCircleButton = ({ colorTheme, onClick }: CircleButtonProps) => {
  return (
    <button {...stylex.props(styles.circleButton, brandColorThemes[colorTheme])} onClick={onClick}>
      <div {...stylex.props(styles.rotated)}>
        <div {...stylex.props(styles.half, styles.topHalf)} />
        <div {...stylex.props(styles.half, styles.bottomHalf)} />
      </div>
    </button>
  );
};
