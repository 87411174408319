import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  italic: {
    fontStyle: 'italic',
  },

  noLineHeight: {
    lineHeight: lineHeight.none,
  },
});

export const colorStyles = stylex.create({
  inherit: {
    color: 'inherit',
  },

  black: {
    color: systemColors.textBlack,
  },

  dark: {
    color: systemColors.textDark,
  },

  muted: {
    color: systemColors.textMuted,
  },
});

export const sizeStyles = stylex.create({
  tiny: {
    fontSize: fontSize.tiny,
    lineHeight: lineHeight.tight,
  },

  small: {
    fontSize: fontSize.small,
    lineHeight: lineHeight.snug,
  },

  regular: {
    fontSize: fontSize.regular,
    lineHeight: lineHeight.normal,
  },

  large: {
    fontSize: fontSize.large,
    lineHeight: lineHeight.normal,
  },

  xlarge: {
    fontSize: fontSize.xlarge,
    lineHeight: lineHeight.normal,
  },
});

export const weightStyles = stylex.create({
  light: {
    fontWeight: fontWeight.light,
  },

  regular: {
    fontWeight: fontWeight.regular,
  },

  medium: {
    fontWeight: fontWeight.medium,
  },

  semiBold: {
    fontWeight: fontWeight.semiBold,
  },

  bold: {
    fontWeight: fontWeight.bold,
  },
});

export const wrapStyles = stylex.create({
  wrap: {
    textWrap: 'wrap',
  },

  balance: {
    textWrap: 'balance',
  },

  pretty: {
    textWrap: 'pretty',
  },
});
