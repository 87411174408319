import * as stylex from '@stylexjs/stylex';

import { animationSpeed, brandColors, fontSize, fontWeight, lineHeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

import { buttonVariables } from './Button.stylex';

const isHovered = ':is([data-hover])';
const isFocused = ':is([data-focus])';
const isActive = ':is([data-active])';
const isDisabled = ':is([data-disabled])';

const outlineFadeIn = stylex.keyframes({
  '0%': {
    outlineColor: 'transparent',
    outlineOffset: `calc(${buttonVariables.outlineOffset} + 2px)`,
  },
  '100%': {
    outlineColor: buttonVariables.outlineColor,
    outlineOffset: buttonVariables.outlineOffset,
  },
});

export const styles = stylex.create({
  base: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xsmall,
    borderWidth: 1,
    borderRadius: radius.xxxsmall,
    fontSize: fontSize.regular,
    fontWeight: fontWeight.semiBold,
    lineHeight: lineHeight.snug,
    textDecoration: 'none',
    textAlign: 'center',
    cursor: {
      default: 'pointer',
      [isDisabled]: 'not-allowed',
    },
    transitionProperty: 'color, border-color, background-color',
    transitionDuration: animationSpeed.fast,
    outlineStyle: {
      default: 'none',
      [isFocused]: 'solid',
    },
    outlineWidth: '1px',
    outlineOffset: buttonVariables.outlineOffset,
    outlineColor: buttonVariables.outlineColor,
    animationName: {
      default: null,
      [isFocused]: outlineFadeIn,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
  },

  contentWrapper: {
    display: {
      default: null,
      ':empty': 'none',
    },
  },

  loading: {
    cursor: {
      default: 'pointer',
      [isDisabled]: 'wait',
    },
  },

  // Size

  narrow: {
    paddingInline: spacing.small,
    paddingBlock: spacing.xxsmall,
  },

  normal: {
    paddingInline: spacing.large,
    paddingBlock: spacing.xxsmall,
  },

  large: {
    paddingInline: spacing.large,
    paddingBlock: spacing.small,
  },

  square: {
    paddingInline: 0,
    paddingBlock: 0,
    width: 40,
    height: 40,
    flexShrink: 0,
  },

  // Variants

  brand: {
    color: {
      default: buttonVariables.colorLight,
      [isHovered]: buttonVariables.colorDark,
      [isActive]: buttonVariables.colorDark,
      [isDisabled]: buttonVariables.colorDisabledDark,
    },
    borderColor: {
      default: brandColors.primary500,
      [isDisabled]: buttonVariables.colorDisabledLight,
    },
    backgroundColor: {
      default: brandColors.primary500,
      [isHovered]: buttonVariables.colorLight,
      [isActive]: buttonVariables.colorLight,
      [isDisabled]: buttonVariables.colorDisabledLight,
    },
    [buttonVariables.outlineColor]: brandColors.primary500,
  },

  primary: {
    color: {
      default: buttonVariables.colorLight,
      [isHovered]: buttonVariables.colorDark,
      [isActive]: buttonVariables.colorDark,
      [isDisabled]: buttonVariables.colorDisabledDark,
    },
    borderColor: {
      default: buttonVariables.colorDark,
      [isDisabled]: buttonVariables.colorDisabledLight,
    },
    backgroundColor: {
      default: buttonVariables.colorDark,
      [isHovered]: buttonVariables.colorLight,
      [isActive]: buttonVariables.colorLight,
      [isDisabled]: buttonVariables.colorDisabledLight,
    },
  },

  secondary: {
    color: {
      default: buttonVariables.colorDark,
      [isHovered]: brandColors.primary500,
      [isActive]: brandColors.primary500,
      [isDisabled]: buttonVariables.colorDisabledDark,
    },
    borderColor: {
      default: buttonVariables.colorDark,
      [isHovered]: brandColors.primary500,
      [isActive]: brandColors.primary500,
      [isDisabled]: buttonVariables.colorDisabledLight,
    },
    backgroundColor: 'transparent',
  },

  tertiary: {
    color: {
      default: buttonVariables.colorDark,
      [isHovered]: brandColors.primary500,
      [isActive]: brandColors.primary500,
      [isDisabled]: buttonVariables.colorDisabledDark,
    },
    borderColor: {
      default: 'transparent',
      [isHovered]: brandColors.primary050,
    },
    backgroundColor: {
      default: 'transparent',
      [isHovered]: brandColors.primary050,
    },
  },

  link: {
    padding: 0,
    display: 'inline-flex',
    gap: spacing.xxsmall,
    alignItems: 'baseline',
    fontSize: 'inherit',
    fontWeight: fontWeight.medium,
    lineHeight: 'inherit',
    color: {
      default: brandColors.primary500,
      [isHovered]: brandColors.primary400,
      [isFocused]: brandColors.primary400,
      [isDisabled]: systemColors.textMuted,
    },
    borderStyle: 'none',
    [buttonVariables.outlineColor]: 'currentColor',
    [buttonVariables.outlineOffset]: '1px',
    textDecoration: {
      default: 'underline',
      [isDisabled]: 'none',
    },
    textAlign: 'start',
  },

  ghost: {
    padding: 0,
    display: 'inline-flex',
    gap: spacing.xxsmall,
    alignItems: 'baseline',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    color: {
      default: 'inherit',
      [isDisabled]: systemColors.textMuted,
    },
    borderStyle: 'none',
    [buttonVariables.outlineColor]: 'currentColor',
    [buttonVariables.outlineOffset]: '1px',
    textAlign: 'start',
  },

  // Scale

  scale: {
    width: '100%',
  },
});
