import * as stylex from '@stylexjs/stylex';

import type { MediaQueryMobileAndBelow, MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { animationSpeed, fontSize, spacing, systemColors } from '~/styles/tokens.stylex';

const MobileAndBelow: MediaQueryMobileAndBelow = '@media (width < 768px)';
const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

const outlineFadeIn = stylex.keyframes({
  '0%': {
    outlineColor: 'transparent',
    outlineOffset: '2px',
  },
  '100%': {
    outlineColor: systemColors.outline,
    outlineOffset: '0px',
  },
});

export const styles = stylex.create({
  header: {
    gridArea: 'header',
    marginInline: {
      default: spacing.normal,
      [TabletAndBelow]: spacing.none,
    },
    display: 'flex',
    alignItems: 'center',
    gap: {
      default: spacing.xsmall,
      [MobileAndBelow]: spacing.xxsmall,
    },
  },

  logoLink: {
    flexShrink: 0,
    marginInlineEnd: 'auto',
    color: systemColors.textBlack,
  },

  logo: {
    color: systemColors.textBlack,
    height: {
      default: 24,
      [MobileAndBelow]: 18,
    },
  },

  languageListboxButton: {
    width: 'fit-content',
    minHeight: 32,
    paddingInlineStart: spacing.xxsmall,
    paddingInlineEnd: 0,
    borderStyle: 'none',
    fontSize: fontSize.small,
    color: systemColors.textMuted,
    outlineStyle: {
      default: 'none',
      ':is([data-focus])': 'solid',
    },
    outlineWidth: '1px',
    outlineColor: systemColors.outline,
    animationName: {
      default: null,
      ':is([data-focus])': outlineFadeIn,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
  },

  languageListboxOptions: {
    // The listbox button takes the width of the selected value,
    // which could cause the options to clip if one of the options
    // is shorter than the selected value.
    minWidth: 'min-content',
    padding: spacing.xxxsmall,
    fontSize: fontSize.small,
  },

  languageFull: {
    display: {
      default: null,
      [MobileAndBelow]: 'none',
    },
  },

  languageShort: {
    display: {
      default: 'none',
      [MobileAndBelow]: 'unset',
    },
  },
});
