import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  icon: {
    display: 'block',
    flexShrink: 0,
    fill: 'currentColor',
  },

  size: (size: number) => ({
    width: size,
    height: size,
  }),

  inline: {
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    height: 'unset',
  },
});
