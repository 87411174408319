import * as stylex from '@stylexjs/stylex';

import { fontSize, radius, spacing, statusColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  tooltipTrigger: {
    borderRadius: radius.full,
  },

  tag: {
    paddingInline: spacing.xxsmall,
    paddingBlock: spacing.tiny,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxxsmall,
    fontSize: fontSize.tiny,
    borderRadius: radius.full,
    borderWidth: 1,
    cursor: 'default',
  },

  tagSuccess: {
    color: statusColors.successDark,
    backgroundColor: statusColors.successLight,
    borderColor: statusColors.successDark,
  },

  tagWarning: {
    color: statusColors.warningDark,
    backgroundColor: statusColors.warningLight,
    borderColor: statusColors.warningDark,
  },

  iconSuccess: {
    color: statusColors.successMuted,
  },

  iconWarning: {
    color: statusColors.warningMuted,
  },
});
