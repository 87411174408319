import dayjs from 'dayjs';

import type { Invoice } from '~/types/invoice';

import { getObjectYear } from '~/utils/objects';

import type { InvoiceCalculations, InvoiceOverviewCalculations } from './types';

import { getInvoiceStatus, InvoiceStatus } from '../InvoicesCreditnotes/utils';

/**
 * Filters not finished invoices out on dashboard
 */
const filterInvoices = (invoices: Invoice[], year: number): Invoice[] => {
  return invoices.filter((inv) => !inv.draft && !inv.credited && inv.allowed && getObjectYear(inv) === year);
};

/**
 * Calculates the totalFc and netBenefit per month from the given year
 */
export const getInvoiceCalculations = (invoices: Invoice[], year: number): InvoiceCalculations => {
  const filteredInvoices = filterInvoices(invoices, year);
  return filteredInvoices.reduce(
    (prev, curr) => {
      const month = Number(curr.docDate.split('-')[1]);

      prev.commission[month - 1] = prev.commission[month - 1] + curr.commission;
      prev.copyright[month - 1] = prev.copyright[month - 1] + curr.calculationData.fcCopyrightExclVat;
      prev.netBenefit[month - 1] = prev.netBenefit[month - 1] + curr.legacyCalculationData.netBenefit;
      prev.performance[month - 1] = prev.performance[month - 1] + curr.calculationData.fcPerformanceExclVat;

      return prev;
    },
    {
      commission: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      copyright: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      netBenefit: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      performance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  );
};

/**
 * Calculates the data by the given year for the invoices overview pie chart
 */
export const getInvoiceOverviewCalculations = (invoices: Invoice[], year: number): InvoiceOverviewCalculations => {
  const filteredInvoices = filterInvoices(invoices, year);
  return filteredInvoices.reduce<InvoiceOverviewCalculations>(
    (prev, curr) => {
      const status = getInvoiceStatus(curr);

      // UNPAID EXPIRED
      if (status === InvoiceStatus.EXPIRED) {
        prev.unpaidExpired.amount++;
        prev.unpaidExpired.value += curr.calculationData.fcExclVat;
      }

      // UNPAID PENDING
      if (status === InvoiceStatus.SENT) {
        prev.unpaidPending.amount++;
        prev.unpaidPending.value += curr.calculationData.fcExclVat;
      }

      // PAID
      if (status === InvoiceStatus.FC_PAID) {
        if (dayjs(curr.docDate).quarter() === dayjs().quarter() && dayjs(curr.docDate).year() === year) {
          prev.paidCurrentQ.amount++;
          prev.paidCurrentQ.value += curr.calculationData.fcExclVat;
        } else {
          prev.paidPreviousQ.amount++;
          prev.paidPreviousQ.value += curr.calculationData.fcExclVat;
        }
      }

      prev.total.amount++;
      prev.total.value += curr.calculationData.fcExclVat;

      return prev;
    },
    {
      unpaidExpired: { amount: 0, value: 0 },
      unpaidPending: { amount: 0, value: 0 },
      paidCurrentQ: { amount: 0, value: 0 },
      paidPreviousQ: { amount: 0, value: 0 },
      total: { amount: 0, value: 0 },
    },
  );
};
