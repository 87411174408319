import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductFruits as BaseProductFruits } from 'react-product-fruits';
import { useNavigate } from 'react-router';
import { match } from 'ts-pattern';

import { useInvoices } from '~/api/invoices';
import { useUser } from '~/api/user';
import { env } from '~/constants/env';
import { planIds } from '~/constants/plans';

type Props = {
  workspaceCode: string;
};

export const ProductFruits = ({ workspaceCode }: Props) => {
  const { data: user } = useUser();
  const { data: invoices } = useInvoices();

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const secondaryActivities = user.activities
    .filter((activity) => !activity.isPrimaryActivity)
    .map(({ id }) => t(`common:activities.${id}.label`));
  const invoicesCount = user.invitations.filter(({ status }) => status !== 'declined').length;
  const invitorDiscountsCount = user.invitorDiscounts.filter(({ isActive }) => isActive).length;
  const hasInvoicedIn2025 = invoices.some((invoice) => dayjs(invoice.docDate).year() === 2025);

  const productFruitsUser = useMemo(() => {
    const primaryActivity = user.activities.find((activity) => activity.isPrimaryActivity);

    return {
      username: user.email,
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      signUpAt: user.registeredAt,
      props: {
        primaryActivity: primaryActivity ? t(`common:activities.${primaryActivity.id}.label`) : '',
        secondaryActivities,
        invitorDiscounts: invitorDiscountsCount,
        invitations: invoicesCount,
        isCraftCommunityEnabled: user.communityProfile.enabled,
        isNpsShown: user.showNps,
        hasApprovedInvoice: user.hasApprovedInvoice,
        lastApprovedInvoiceDate:
          user.lastApprovedInvoiceDocDate !== null ? dayjs(user.lastApprovedInvoiceDocDate).unix() : dayjs.unix(0).unix(),
        hasSeenInvitationsPage: user.appSettings.hasSeenInvitationsPage,
        plan: match(user.planId)
          .with(planIds.earlyAdopter, () => 'early adopter')
          .with(planIds.classic, () => 'classic')
          .with(planIds.premium, () => 'premium')
          .with(planIds.shelter, () => 'shelter')
          .exhaustive(),
        hasInvoicedIn2025,
      },
    };
  }, [
    hasInvoicedIn2025,
    invitorDiscountsCount,
    invoicesCount,
    secondaryActivities,
    t,
    user.activities,
    user.appSettings.hasSeenInvitationsPage,
    user.communityProfile.enabled,
    user.email,
    user.firstName,
    user.hasApprovedInvoice,
    user.lastApprovedInvoiceDocDate,
    user.lastName,
    user.planId,
    user.registeredAt,
    user.showNps,
  ]);

  if (env.IS_LOCAL_DEV_ENV) return null;

  return (
    <BaseProductFruits
      config={{
        customNavigation: {
          use: true,
          navigate: (location) => (location.startsWith('/') ? navigate(location) : window.open(location, '_self')),
          onGet: () => window.location.href,
        },
      }}
      language={user.lang}
      user={productFruitsUser}
      workspaceCode={workspaceCode}
    />
  );
};
