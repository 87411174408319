import type { UserCommunityProfile } from '~/api/community/types';
import type { ColorTheme } from '~/api/user/types';
import type { ClassicPlanId, EarlyAdopterPlanId, PremiumPlanId, ShelterPlanId } from '~/constants/plans.types';

import type { Language } from './app';

// TYPES

export type User = {
  accountant: Accountant | null;
  activities: {
    extraInformation: string | null;
    id: Activity;
    isPrimaryActivity: boolean;
  }[];
  addendumSigned: boolean;
  address: Address;
  appSettings: AppSettings;
  brands: Brand[];
  commissionHistoryStart: string | null; // ISO 8601 format
  commissionSettings: CommissionSettingsWithFixedPercentage | CommissionSettingsWithBrackets;
  communityProfile: UserCommunityProfile;
  companyName: string;
  contractSigned: boolean;
  copyright: {
    averageReceivedPastYears: number;
    totalReceivedThisYear: number;
  };
  documents: Doc[];
  eligible: boolean;
  email: string;
  emailSettings: EmailSettings;
  financial: {
    bank: string;
    bic: string;
    iban: string;
  };
  firstName: string;
  hasApprovedInvoice: boolean;
  id: number;
  integrations: {
    billit: boolean;
    cashaca: boolean;
  };
  invitations: Invitation[];
  inviteeDiscount: InviteeDiscount | null;
  invitorDiscounts: {
    id: number;
    invitationId: number | null;
    inviteeName: string;
    isActive: boolean;
    percentage: number;
    validFrom: string; // ISO 8601 format
    validUntil: string; // ISO 8601 format
  }[];
  invoiceForwardingSettings: InvoiceForwardingSetting[];
  kboInformationLastUpdatedAt: string; // ISO 8601 format
  lang: Language;
  lastApprovedInvoiceDocDate: string | null;
  lastName: string;
  logo?: string;
  mainOccupation: MainOccupation;
  numberingSeries: NumberingSeries[];
  phone: string;
  planId: ShelterPlanId | EarlyAdopterPlanId | ClassicPlanId | PremiumPlanId;
  referralCode: string;
  registeredAt: string; // ISO 8601 format
  showNps: boolean;
  showOptionalSteps: boolean;
  showRequiredSteps: boolean;
  totalInvoicedAmount: number;
  vatExempt: boolean | null;
  vatNumber: string;
  yearlyCopyrightLimitExceeded: boolean;
};

export type CommissionSettingsWithFixedPercentage = {
  adminOverride: boolean;
  creativePercentage: number;
  type: 'fixedPercentage';
};

export type CommissionSettingsWithBrackets = {
  adminOverride: boolean;
  creativePercentages: [number, number, number];
  nonCreativePercentages: [number, number, number];
  revenueAmounts: [number, number];
  type: 'brackets';
};

type Accountant = {
  approved: boolean;
  data: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

type Address = {
  city: string;
  number: string;
  street: string;
  zipCode: string;
};

export type InviteeDiscount = {
  /** Remaining number of days, including today. */
  daysActive: number;
  /** Total number of days. */
  daysValid: number;
  referralCode: string;
  type: 'ShelterForFree' | 'PremiumForClassic' | null;
};

export type AppSettings = {
  addressLastVerifiedAt: string;
  /** @see {@link https://creativeshelter.slite.com/app/docs/KUSrq-ZNyEDMVP Slite documentation}  */
  communicationGroup2025: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  hasSeenInvitationsPage: boolean;
  show2025Announcement: boolean;
  showActivityModal: boolean;
  theme: {
    appColor?: ColorTheme;
    invoiceColor?: ColorTheme;
  } | null;
};

export type Brand = {
  color: string;
  companyName: string;
  email: string;
  id: number;
  isFavorite: boolean;
  logo: string | null;
};

export type Doc = {
  extension: string;
  filename: string;
  id: number;
  title: string;
  url: string;
};

export type EmailSettings = {
  bcc: boolean;
  creationReminder: CreationReminder;
  followUpEnabled: boolean;
  invitationReminder: boolean;
  nettoSaved: boolean;
};

export type Invitation = {
  email: string;
  id: number;
  name: string;
  status: 'pending' | 'accepted' | 'declined';
  statusDate: string; // ISO 8601 format
};

export type InvoiceForwardingSetting = {
  frequency: InvoiceForwardingFrequency;
  id: number;
  platformEmail: string;
  receiverEmail: string;
};

export type InvoiceForwardingFrequency = 'instant' | 'monthly' | 'quarterly';

export type NumberingSeries = {
  enabledForCreditNotes: boolean;
  enabledForInvoices: boolean;
  format: {
    amountOfDigits: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null;
    prefixSeparator: NumberingSeparator | null;
    prefixText: string | null;
    prefixYearMonth: NumberingYearMonthFormat | null;
    suffixSeparator: NumberingSeparator | null;
    suffixText: string | null;
    suffixYearMonth: NumberingYearMonthFormat | null;
  };
  id: number;
  name: string;
  nextNumber: number;
  resetInterval: NumberingResetInterval;
  userId: number;
};

export type NumberingSeparator = '_' | '-' | '.' | '/';

// ENUMS

export enum CreationReminder {
  NO_REMINDERS = 0,
  LAST_DAY = 1,
  WEEK_BEFORE = 2,
  TWO_WEEKS_BEFORE = 3,
}

export enum MainOccupation {
  NO = 0,
  YES = 1,
}

export enum Activity {
  VISUAL_COMMUNICATION = 1,
  DIGITAL_DESIGN = 2,
  PHOTOGRAPHY = 3,
  AUDIOVISUAL = 4,
  LANGUAGE_AND_COMMUNICATION = 5,
  IT_DEVELOPMENT = 6,
  EDUCATION = 7,
  PERFORMING_ARTISTS = 8,
  STYLING_AND_INTERIOR = 9,
  ONLINE_CONTENT_CREATION = 10,
  OTHER = 11,
}

export enum NumberingYearMonthFormat {
  FullYear = 'YYYY',
  ShortYear = 'YY',
  FullYearMonth = 'YYYYMM',
  ShortYearMonth = 'YYMM',
}

export enum NumberingResetInterval {
  Never = 'never',
  Yearly = 'yearly',
  Monthly = 'monthly',
}
