export const community = {
  header: {
    activateProfileLink: 'Rendre votre profil public',
    description: 'Trouvez d’autres freelances et connectez-vous les uns aux autres',
    editProfileLink: 'Modifiez votre profil',
    preview: 'Vérifiez votre profil',
    title: 'Craft Community',
  },
  overview: {
    errorLoading:
      "Une erreur s'est produite lors de la récupération des données. Si le problème persiste, contactez <a href='mailto:service@creativeshelter.be'> service@creativeshelter.be</a>.",
    filters: {
      activity: {
        label: 'Activités',
        placeholder: 'Toutes les activités',
      },
      name: {
        placeholder: 'Recherche par nom',
      },
      openToWork: {
        label: 'Disponibilité',
        text: 'Disponible pour des missions',
      },
      region: {
        label: 'Région',
        placeholder: 'Toutes les régions',
      },
    },
    loading: 'Chargement en cours...',
    locked: 'Rendez votre profil public pour faire partie de la Craft Community',
    resetFilters: '(effacer les filtres)',
    resultCount_one: '{{count}} résultat',
    resultCount_other: '{{count}} résultats',
    resultCount_zero: "Aucune correspondance n'a été trouvée en fonction de vos critères de recherche",
    scrollToTop: 'Retour en haut de page',
    showMore: 'Afficher plus',
  },
  profileCard: {
    activeInRegion: 'Actif dans {{regions}}',
    mailLink: 'Envoyez-moi un mail',
    openToWork: 'Disponible pour des missions',
  },
  regions: {
    Antwerp: 'Anvers',
    Brussels: 'Bruxelles',
    EastFlanders: 'Flandre orientale',
    FlemishBrabant: 'Brabant flamand',
    Hainaut: 'Hainaut',
    Liege: 'Liège',
    Limburg: 'Limbourg',
    Luxembourg: 'Luxembourg',
    Namur: 'Namur',
    WalloonBrabant: 'Brabant wallon',
    WestFlanders: 'Flandre occidentale',
  },
};
