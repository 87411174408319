import * as stylex from '@stylexjs/stylex';

import { brandColors, neutralColors, spacing } from '~/styles/tokens.stylex';

const spin = stylex.keyframes({
  '0%': { transform: 'rotate(0)' },
  '35%': { transform: 'rotate(730deg)' },
  '45%': { transform: 'rotate(717deg)' },
  '55%': { transform: 'rotate(722deg)' },
  '65%': { transform: 'rotate(720deg)' },
  '100%': { transform: 'rotate(720deg)' },
});

export const styles = stylex.create({
  fullscreen: {
    position: 'fixed',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.normal,
    padding: spacing.normal,
    backgroundColor: neutralColors.white,
  },

  logo: {
    width: 60,
    color: brandColors.secondary500,
    animationName: spin,
    animationTimingFunction: 'ease-out',
    animationDuration: '2.2s',
    animationIterationCount: 'infinite',
    animationDelay: '0.5s',
  },

  message: {
    textAlign: 'center',
  },
});
