import { Trans } from 'react-i18next';

import type { InviteeDiscount } from '~/types/user';

import { LinkButton } from '~/components/UI';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { routes } from '~/providers/RouterProvider/router.routes';

import { styles } from './InviteeDiscountCountdown.styles';

type Props = {
  discount: InviteeDiscount | null;
};

export const InviteeDiscountCountdown = ({ discount }: Props) => {
  const screen = useScreenWidth();

  if (discount?.type !== 'ShelterForFree' || screen.isSmallerThan('md')) {
    return null;
  }

  return (
    <LinkButton icon={'Redeem'} size="narrow" styles={styles.linkButton} to={routes.settingsPricing} variant="secondary">
      {screen.isLargerThan('md') && <Trans count={discount.daysActive} i18nKey={'common:topbar.activePremiumForFree'} />}
    </LinkButton>
  );
};
