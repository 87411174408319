import { LogoSymbol } from '../SVG';
import styles from './SpinnerLogo.module.scss';

export const SpinnerLogo = () => {
  return (
    <div className={styles.spinnerLogo}>
      <LogoSymbol />
    </div>
  );
};
