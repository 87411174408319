export const common = {
  activities: {
    '1': {
      description: 'Graphic Design, Illustration, Cartoon, Industrial Design, Brand Design, Art Direction, 3D Art,...',
      label: 'Visual communication',
    },
    '10': {
      description: 'Social media',
      label: 'Online content creation and storytelling',
    },
    '11': {
      label: 'Other',
    },
    '2': {
      description: 'Webdesign, UI/UX,...',
      label: 'Digital design',
    },
    '3': {
      description: 'Photography, post-production,...',
      label: 'Photography',
    },
    '4': {
      description: 'Videography, Editing, Voice-over, Motion design, Sound design,...',
      label: 'Audiovisual',
    },
    '5': {
      description: 'Copywriting, Translation, Editor,...',
      label: 'Language and communication',
    },
    '6': {
      description: 'IT-development, Web-development, Game-development,...',
      label: 'IT development',
    },
    '7': {
      description: 'Course, Workshop,...',
      label: 'Training',
    },
    '8': {
      description: 'Model, Presenter, Actor,...',
      label: 'Performing artist',
    },
    '9': {
      description: 'Styling, Food Styling, Interior design,...',
      label: 'Styling & interior',
    },
  },
  add: 'Add',
  autoSave: 'Saving...',
  blocker: {
    leave: 'Exit without saving',
    stay: 'Stay on page',
    title: 'Leave page',
  },
  breadcrumbs: {
    invoiceDetails: 'Invoice details',
    invoices: 'All invoices',
  },
  cancel: 'Cancel',
  client: {
    privateClient: 'Private person',
  },
  collapseMenu: 'Unfold menu',
  colorPicker: {
    label: 'Change colour scheme',
  },
  customAgreementOptionLabel: 'According to the framework agreement',
  datatable: {
    all: 'all',
    delete: 'Remove',
    download: 'Download',
    edit: 'Edit',
    hideFilters: 'Hide filters',
    noData: 'No results found.',
    search: 'Search',
    selection: {
      amountSelected: '{{amount}} selected.',
      removeSelection: 'Remove selection',
      selectAll: 'Select all',
    },
    show: 'Show',
    showFilters: 'Show filters',
  },
  delete: 'Remove',
  draft: {
    defaultClient: 'No client selected',
    defaultTitle: 'No title',
    deleteModal: {
      description: 'Are you sure you want to delete the concept?',
      submit: 'Delete concept',
      title: 'Delete concept',
    },
    overviewTitle: 'Concept quotations',
  },
  edit: 'Modify',
  error: 'Something went wrong. Forward the problem to service@creativeshelter.be.',
  errorBoundaryMessage:
    'Please contact <a href="mailto:service@creativeshelter.be"> service@creativeshelter.be</a> if the problem persists.',
  errors: {
    fetchError: 'No connection to the server. Check your connection and try again.',
    httpErrorWithLink:
      'Something went wrong. Please forward the issue to <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be</email>.',
    tooManyRequests: 'Overload! You tried too many times. Please wait a minute and try again.',
    unknown: 'There has been an unexpected error.',
  },
  export: 'Export',
  fields: {
    email: {
      label: 'Email address',
      placeholder: 'E-mail address',
    },
  },
  greetings: {
    common: 'Hello',
    evening: 'Good evening',
    morning: 'Good morning',
    noon: 'Good afternoon',
  },
  languages: {
    en: 'English',
    fr: 'French',
    nl: 'Dutch',
  },
  languagesShort: {
    en: 'EN',
    fr: 'FR',
    nl: 'NL',
  },
  listbox: {
    noOptions: 'No results',
    noResults: 'No results',
    search: 'Search...',
  },
  loading: 'Loading data...',
  loadingError:
    "Something went wrong while loading the data. <br /><small>Contact <a href='mailto:service@creativeshelter.be'>service@creativeshelter.be</a>.</small>",
  maintenance: {
    description: "We are working on the platform for a while, but will be back soon so that you won't have to miss us for too long.",
    title: 'Away for a moment, but back soon!',
  },
  models: {
    quotation: 'Quotation',
  },
  ok: 'OK',
  optionalInput: '(optional)',
  placeholders: {
    file: 'Choose your file',
    richText: 'Type here...',
    select: 'Select...',
    tagInput: 'Press enter to add a new tag.',
  },
  refresh: {
    button: 'Refresh page',
    message: 'A new version of the platform is available!',
  },
  save: 'Save',
  saved: 'Saved',
  send: 'Send',
  sidebar: {
    clients: 'Clients',
    community: 'Community',
    dashboard: 'Dashboard',
    groupInvoicing: 'Invoicing',
    groupSocial: 'Network',
    invoices: 'Invoices',
    products: 'Products',
    quotations: 'Quotations',
  },
  topbar: {
    activePremiumForFree_one: 'Last day of free billing',
    activePremiumForFree_other: 'Free billing for {{count}} more days',
    dropdown: {
      logout: 'Log out',
    },
    invite: 'Earn 25% discount',
  },
};
