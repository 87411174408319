export const auth = {
  activationPage: {
    loading: 'Activate account...',
  },
  footer: {
    socialLinks: {
      facebook: 'https://www.facebook.com/creativeshelter/',
      instagram: 'https://www.instagram.com/creativeshelter/',
      linkedIn: 'https://be.linkedin.com/company/creative-shelter/',
      website: 'https://www.creativeshelter.be/',
    },
    trademark: 'Creative Shelter © {{year}}',
  },
  forgotPasswordPage: {
    errors: {
      ineligible: {
        description:
          "You can't request a new password if your account hasn't been approved yet. We'll double-check your profile and activities, you'll hear from us soon!",
        title: 'Waiting for approval',
      },
      rejected: {
        description:
          'We have reviewed your profile and you are not eligible to invoice through Creative Shelter. Therefore, your account has unfortunately been deactivated.',
        title: 'Sorry, you are not eligible',
      },
      unverifiedEmail: {
        description:
          'You cannot request a new password if your email address has not been verified. Please check your mailbox and verify the necessary information.',
        title: 'Waiting for verification',
      },
    },
    form: {
      submitButton: 'Email me the reset link',
    },
    header: {
      pill: 'Oops, forgot',
      subtitle: "Enter your email address below. We'll send you the necessary “Oops Forgot Password” email so you can reset it!",
      title: 'Forgot password?',
    },
    loginLink: 'Log in',
    successAlert: {
      description:
        "The information to reset your password has been sent to the email address provided. Didn't receive an email? Please make sure you entered the correct email address.",
      title: 'Email sent!',
    },
    tokenExpiredAlert: {
      description: 'Request a new link here to change your password.',
      title: 'Link expired',
    },
  },
  loginPage: {
    accountantLoginCta: {
      action: '<accountantLink href="https://api.creativeshelter.be/accountants/login">Log in here </accountantLink>',
      message: 'Are you an accountant?',
    },
    errors: {
      ineligible: {
        description:
          "You can't log in yet because you're not automatically eligible. We'll double-check your profile and activities, you'll hear more from us soon!",
        title: 'Waiting for approval',
      },
      invalidEmailOrPassword: 'Oops! Something went wrong, your email address and/or password are incorrect.',
      rejected: {
        description:
          "We've checked your profile and you're not eligible to invoice through Creative Shelter. Unfortunately, your account has been deactivated.",
        title: 'Sorry, you are not eligible',
      },
      unverifiedEmail: {
        description: 'Your email address has not yet been verified. Please check your mailbox and verify your email address.',
        title: 'Waiting for verification',
      },
    },
    forgotPasswordLink: 'Forgot password?',
    form: {
      emailField: {
        label: 'Email address',
      },
      passwordField: {
        label: 'Password',
      },
      submitButton: 'Login',
    },
    header: {
      pill: 'Login',
      subtitle: 'Log in to continue to the platform.',
      title: 'Welcome back!',
    },
    registerCta: {
      action: 'Register',
      message: 'Don’t have an account?',
    },
    sessionExpiredAlert: {
      description: 'Please sign in again to continue.',
      title: 'Session expired',
    },
  },
  registrationPage: {
    common: {
      header: {
        pill: 'Registration',
      },
      loginCta: {
        action: 'Log in',
        message: 'Already have an account?',
      },
      previousStepButton: 'Previous',
      referralCode: {
        addButton: 'Discount code',
        applied: 'Discount!',
        editButton: 'Modify',
        form: {
          invitationsField: {
            description:
              'We see that several freelancers have invited you. Choose which invitation you want to accept, or enter a (different) discount code yourself.',
            invitedAt: 'Invited on {{date}}',
          },
          referralCodeField: {
            errors: {
              expired: 'This discount code has expired.',
              generic: 'The discount code cannot be validated at the moment.',
              invalid: 'This discount code is not valid.',
            },
            invitationDescription: "You've been invited by {{name}} with discount code <code>{{code}}</code> .",
            label: 'Discount code',
          },
          submitButton: 'Apply',
        },
        types: {
          fromAccountant: 'The discount code {{referralCode}} of accountant {{name}} has been successfully applied!',
          fromAdmin: {
            generic: 'The discount code {{referralCode}} has been applied successfully!',
          },
          fromUser: 'Discount thanks to the referral {{referralCode}} from {{name}}! Create your first invoice and both enjoy a discount!',
        },
      },
    },
    stepAccountInformation: {
      alertNoElibibleNacebelCodes: {
        description: 'You can continue with your registration, but we will activate your account after we manually check your activities.',
        title: 'You are not eligible by default',
      },
      form: {
        callingCodeField: {
          options: {
            other: 'Other',
          },
        },
        emailField: {
          errors: {
            exists: 'This email address is already in use, please double check.',
            generic:
              'Something went wrong while validating your email address. </email>If the problem persists, please contact <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be.',
          },
          label: 'Email address',
        },
        passwordField: {
          description: 'Choose a strong password of at least 8 characters. And feel free to make it original!',
          label: 'Password',
        },
        phoneNumberField: {
          label: 'Mobile phone number',
        },
        submitButton: 'Next',
      },
      header: {
        subtitle: 'Ready to dive in?',
        title: 'Hi {{name}}',
      },
    },
    stepBusinessInformation: {
      alertItDevelopers: {
        description:
          'Since 2024, you can no longer receive copyright compensation for computer program performance. You are still eligible for other creative activities.',
        title: 'Important! IT developer? Please read on.',
      },
      errors: {
        fallback: 'An unexpected error occurred during your registration.',
        httpError:
          'Something went wrong while registering. </email>If the problem persists, please contact <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be.',
      },
      form: {
        mainOccupationField: {
          label: 'Status',
          options: {
            mainOccupation: 'Main occupation',
            secondaryOccupation: 'Secondary occupation',
          },
        },
        otherActivitiesField: {
          label: 'Describe your extra (creative) activities',
        },
        primaryActivityField: {
          label: 'Main activity',
        },
        secondaryActivityField: {
          label: 'Do you have any other (creative) activities?',
        },
        submitButton: 'Create account',
      },
      header: {
        subtitle: 'Tell us a little bit about your sole proprietorship.',
        title: 'Hi {{name}}',
      },
    },
    stepCompany: {
      alert: {
        description:
          'Our platform is designed for sole traders, so it doesn\'t apply to you. Fortunately, Creative Shelter also offers a solution for companies. <break/>Interested? <kiiaLink href="https://www.creativeshelter.be/kom-ik-in-aanmerking">Check your eligibility</kiiaLink>.',
        title: 'Creative Shelter for companies',
      },
      changeVatNumber: {
        action: 'Edit',
        message: 'Not the correct VAT number?',
      },
      header: {
        subtitle: 'We see that you have a company.',
        title: 'Hi, director of {{name}}',
      },
    },
    stepSuccess: {
      header: {
        subtitle: {
          eligible: 'Your account has been successfully created!',
          ineligible: 'Data well received!',
        },
        title: "You've reached the finish line!",
      },
      linkAccountant: {
        errors: {
          generic: 'Something went wrong while linking your accountant.',
        },
        form: {
          accountantEmailAddressField: {
            description:
              "Feel free to enter your accountant's email address below. Is she/he not yet familiar with Creative Shelter? We would gladly like to introduce ourselves!",
            label: 'Do you work with an accountant?',
          },
        },
        success: {
          message: 'We have received the information of your accountant, we will start working on it!',
          title: 'Thanks for the info!',
        },
      },
      message: {
        eligible: 'An email has been sent to <bold> {{email}}</bold>. Follow the instructions in this email to activate your account.',
        ineligible:
          'We will double check whether you are eligible to invoice via Creative Shelter.<break/> You can close this window for now, we will inform you quickly by email!',
      },
    },
    stepVatNumber: {
      alert: {
        kboNotAvailable: {
          description:
            'There is a temporary glitch at the CBE, which means we cannot validate VAT numbers at the moment. Please wait and try again later<break />Thanks in advance for your patience!!!',
          title: 'The CBE can temporarily not be reached ',
        },
      },
      form: {
        submitButton: "Let's go!",
        vatNumberField: {
          description:
            'Don\'t have a VAT number yet? <faqLink href="https://www.creativeshelter.be/knowledge-hub/kan-ik-mij-al-aansluiten-bij-creative-shelter-als-mijn-btw-nummer-nog-in-aanvraag-is">Read here</faqLink> what to do.',
          errors: {
            exists: 'There is already an account with this VAT number, please double check.',
            fallback: 'There was an unexpected error while validating your VAT number.',
            httpError:
              'Something went wrong while validating your VAT number. </email>If the problem persists, please contact <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be.',
            inactiveVatNumber:
              'Your VAT number is not yet active according to the CBE. You can only register with an active VAT number, please try again later.',
            invalidVatNumber: 'The VAT number provided does not exist in the CBE. Please double-check.',
            vatNumberChecksum: 'The VAT number does not seem to be correct. Could you please check whether there is an error?',
            vatNumberLength: 'A VAT number consists of 10 digits, with the first digit being 0 or 1.',
          },
          label: 'VAT number',
        },
      },
      header: {
        subtitle: 'Create your free Creative Shelter account here',
        title: 'Get more out of your copyrights',
      },
    },
  },
  resendVerificationEmail: {
    error: 'Something went wrong while sending the verification email. Please contact service@creativeshelter.be.',
    send: "Didn't receive anything? <button>Click here</button> to resend the email.",
    sent: "We sent a new verification email to <bold>{{emailAddress}}</bold>. Still haven't received anything? Please try again after {{seconds}} seconds.",
  },
  resetPasswordPage: {
    errors: {
      ineligible: {
        description:
          "You can't change your password if your account isn't approved. We'll double-check your profile and activities, you'll hear more from us soon!",
        title: 'Waiting for approval',
      },
      unverifiedEmail: {
        description:
          "You can't change your password until your email address is verified. Please check your mailbox and verify what is necessary.",
        title: 'Waiting for verification',
      },
    },
    form: {
      newPasswordField: {
        label: 'New password',
      },
      submitButton: 'Change password',
    },
    header: {
      subtitle: 'Choose a new password for your account.',
      title: 'Reset password',
    },
  },
  sidebars: {
    checklist: {
      features: {
        first: 'Your copyrights arranged quickly and efficiently via our platform.',
        fourth: 'Top service? Included as standard with Creative Shelter!',
        second: 'Earn more from your creative invoices, hassle-free.',
        third:
          'With us, you are <insuranceFaqLink href="https://www.creativeshelter.be/knowledge-hub/hoe-ben-ik-verzekerd-bij-creative-shelter"> triple-insured</insuranceFaqLink>.',
      },
    },
    kiia: {
      eligibilityTest: {
        button: 'Do the check',
        description: 'Such an advantage does come with some conditions. <break />Would you like to be sure that you comply with those?',
        kiiaLink: 'https://www.creativeshelter.be/kom-ik-in-aanmerking',
        title: 'Are you eligible?',
      },
      text: {
        first:
          'The only billing platform that makes you money. <break/>Earn more as a creative freelancer by redeeming your copyrights through Creative Shelter.',
        second: 'Your copyrights are worth their weight in gold.',
      },
    },
    testimonials: {
      indiana: {
        body: "It took me a while to take the time to register and get everything in order, but I'm so glad I did this. Meanwhile, Creative Shelter is a tab that is open by default and that I use every day. Very nice to see how much extra profit you make compared to a standard invoice.",
        name: 'Indiana De Clerq',
        title: 'More profit compared to a standard invoice',
      },
      joelle: {
        body: 'Very happy with Creative Shelter! 😊 They are very user-friendly, accurate and clear, always offer friendly service and timely and effective follow-up. Highly recommended!',
        name: 'Joelle Batens',
        title: 'Easy to use, accurate and clear',
      },
      toon: {
        body: 'A very satisfied user of the Creative Shelter platform for +5 years, a must for any freelance creative!',
        name: 'Toon Van Craeyevelt',
        title: 'Recommended for any freelance creative',
      },
    },
  },
};
