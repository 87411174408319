import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/SVG';
import { Text, Title } from '~/components/UI';

import { styles } from './PricingFeatures.styles';

export const PricingFeatures = () => {
  const { t } = useTranslation(['pricing']);

  const groupedFeatures = [
    {
      title: t('pricing:pricingFeatures.group1.title'),
      features: [
        t('pricing:pricingFeatures.group1.feature1.label'),
        t('pricing:pricingFeatures.group1.feature2.label'),
        t('pricing:pricingFeatures.group1.feature3.label'),
        t('pricing:pricingFeatures.group1.feature4.label'),
      ],
    },
    {
      title: t('pricing:pricingFeatures.group2.title'),
      features: [
        t('pricing:pricingFeatures.group2.feature1.label'),
        t('pricing:pricingFeatures.group2.feature2.label'),
        t('pricing:pricingFeatures.group2.feature3.label'),
        t('pricing:pricingFeatures.group2.feature4.label'),
      ],
    },
    {
      title: t('pricing:pricingFeatures.group3.title'),
      features: [
        t('pricing:pricingFeatures.group3.feature1.label'),
        t('pricing:pricingFeatures.group3.feature2.label'),
        t('pricing:pricingFeatures.group3.feature3.label'),
        t('pricing:pricingFeatures.group3.feature4.label'),
      ],
    },
  ];

  return (
    <section {...stylex.props(styles.wrapper)}>
      <Title as="h4">{t('pricing:pricingFeatures.title')}</Title>

      <div {...stylex.props(styles.featureCategories)}>
        {groupedFeatures.map(({ features, title }) => (
          <div {...stylex.props(styles.featureCategory)} key={title}>
            <Text color="black" weight="semiBold">
              {title}
            </Text>

            <ul {...stylex.props(styles.features)}>
              {features.map((feature) => (
                <li {...stylex.props(styles.feature)} key={feature}>
                  <Icon name="Check" styles={styles.featureCheckIcon} />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};
