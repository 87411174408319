export const common = {
  activities: {
    '1': {
      description: 'Design graphique, Illustration, Dessin animé, Design industriel, Design de marque, Direction artistique, Art 3D,...',
      label: 'Communication visuelle',
    },
    '10': {
      description: 'Médias sociaux',
      label: 'Création de contenu en ligne et storytelling',
    },
    '11': {
      label: 'Autre',
    },
    '2': {
      description: 'Webdesign, UI/UX,...',
      label: 'Design digital',
    },
    '3': {
      description: 'Photographie, post-production,...',
      label: 'Photographie',
    },
    '4': {
      description: 'Vidéographie, Montage, Voix-off, Motion design, Sound design,...',
      label: 'Audiovisuel',
    },
    '5': {
      description: 'Rédaction, Traduction, Révision,...',
      label: 'Langue et communication',
    },
    '6': {
      description: 'Développement informatique, Développement Web, Développement de jeux',
      label: 'Développement informatique',
    },
    '7': {
      description: 'Cours, Workshop,...',
      label: 'Formation',
    },
    '8': {
      description: 'Mannequin, Présentateur, Acteur,...',
      label: 'Artiste de scène',
    },
    '9': {
      description: "Styling, Foodstyling, Design d'intérieur, ...",
      label: "Stylisme et décoration d'intérieur",
    },
  },
  add: 'Ajouter',
  autoSave: 'Sauvegarder...',
  blocker: {
    leave: 'Quitter sans sauvegarder',
    stay: 'Rester sur la page',
    title: 'Quitter la page',
  },
  breadcrumbs: {
    invoiceDetails: 'Détails de la facture',
    invoices: 'Toutes les factures',
  },
  cancel: 'Annuler',
  client: {
    privateClient: 'Privé',
  },
  collapseMenu: 'Réduire le menu',
  colorPicker: {
    label: 'Modifier le thème des couleurs',
  },
  customAgreementOptionLabel: 'Selon le contrat-cadre',
  datatable: {
    all: 'tout',
    delete: 'Supprimer',
    download: 'Télécharger',
    edit: 'Éditer',
    hideFilters: 'Masquer les filtres',
    noData: 'Aucun résultat trouvé.',
    search: 'Chercher',
    selection: {
      amountSelected: '{{amount}} sélectionné.',
      removeSelection: 'Supprimer la sélection',
      selectAll: 'Tout sélectionner',
    },
    show: 'Afficher',
    showFilters: 'Afficher les filtres',
  },
  delete: 'Supprimer',
  draft: {
    defaultClient: 'Aucun client sélectionné',
    defaultTitle: 'Pas de titre',
    deleteModal: {
      description: 'Êtes-vous sûr de vouloir supprimer le concept ?',
      submit: 'Supprimer le concept',
      title: 'Supprimer le concept',
    },
    overviewTitle: 'Concepts',
  },
  edit: 'Modifier',
  error: "Une erreur s'est produite . Transmettre le problème à service@creativeshelter.be.",
  errorBoundaryMessage:
    'Si le problème persiste, veuillez contacter <a href="mailto:service@creativeshelter.be">service@creativeshelter.be</a>.',
  errors: {
    fetchError: 'Aucune connexion au serveur. Vérifiez votre connexion et réessayez.',
    httpErrorWithLink:
      'Une erreur s\'est produite. Veuillez transmettre le problème à <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be</email>.',
    tooManyRequests: 'Surcharge ! Vous avez essayé trop de fois. Veuillez attendre et réessayer.',
    unknown: "Une erreur inattendue s'est produite.",
  },
  export: 'Exporter',
  fields: {
    email: {
      label: 'Adresse e-mail',
      placeholder: 'Adresse mail',
    },
  },
  greetings: {
    common: 'Bonjour',
    evening: 'Bonsoir',
    morning: 'Bonjour',
    noon: 'Bonjour',
  },
  languages: {
    en: 'anglais',
    fr: 'français',
    nl: 'néerlandais',
  },
  languagesShort: {
    en: 'EN',
    fr: 'FR',
    nl: 'NL',
  },
  listbox: {
    noOptions: 'Aucun résultat',
    noResults: 'Aucun résultat',
    search: 'Chercher...',
  },
  loading: 'Chargement des données...',
  loadingError:
    "Une erreur s'est produite lors du chargement des données. <br /><small>Contactez <a href='mailto:service@creativeshelter.be'>service@creativeshelter.be</a>.</small>",
  maintenance: {
    description: 'Nous travaillons à la plateforme pendant un certain temps, nous serons bientôt de retour.',
    title: 'Absent pour le moment, mais de retour très bientôt !',
  },
  models: {
    quotation: 'Offre',
  },
  ok: "d'accord",
  optionalInput: '(optionnel)',
  placeholders: {
    file: 'Choisissez votre fichier',
    richText: 'Taper ici...',
    select: 'Sélectionner...',
    tagInput: 'Appuyez sur Entrée pour ajouter un nouveau tag.',
  },
  refresh: {
    button: 'Rafraîchir la page',
    message: 'Une nouvelle version de la plateforme est disponible !',
  },
  save: 'Enregistrer',
  saved: 'Sauvegardé',
  send: 'Envoter',
  sidebar: {
    clients: 'Les clients',
    community: 'Community',
    dashboard: 'Tableau de bord',
    groupInvoicing: 'Facturation',
    groupSocial: 'Réseau',
    invoices: 'Factures',
    products: 'Produits',
    quotations: 'Offres',
  },
  topbar: {
    activePremiumForFree_one: 'Dernier jour de facturation gratuite',
    activePremiumForFree_other: 'Encore {{count}} jours de facturation gratuite',
    dropdown: {
      logout: 'Se déconnecter',
    },
    invite: 'Bénéficiez de 25 % de réduction',
  },
};
