import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useInvoices } from '~/api/invoices';
import { useUser } from '~/api/user';
import { Icon } from '~/components/SVG';
import { Button, Separator, Span, Text, Title } from '~/components/UI';
import { useDialog } from '~/hooks/useDialog';
import { useIntl } from '~/hooks/useIntl';

import { styles } from './DiscountsCard.styles';
import { SendInvitationDialog } from './SendInvitationDialog';

export const DiscountsCard = () => {
  const { data: user } = useUser();
  const { data: invoices } = useInvoices();

  const sendInvitationDialog = useDialog();

  const { formatCurrency, formatDate } = useIntl();
  const { t } = useTranslation(['invitations']);

  const discounts = useMemo(
    () => [...user.invitorDiscounts].sort(({ validUntil: a }, { validUntil: b }) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1)),
    [user.invitorDiscounts],
  );
  const activeDiscounts = useMemo(() => discounts.filter(({ isActive }) => isActive), [discounts]);
  const expiredDiscounts = useMemo(() => discounts.filter(({ isActive }) => !isActive), [discounts]);

  const referralProfit = invoices.reduce((total, { referralProfit }) => total + referralProfit, 0);

  return (
    <>
      <article {...stylex.props(styles.card)}>
        <header {...stylex.props(styles.header)}>
          <Icon name="Sell" />
          <Title as="h6">{t('invitations:overview.discounts.title')}</Title>
        </header>

        <Separator spacing="normal" />

        {discounts.length === 0 ? (
          <p {...stylex.props(styles.noDiscounts)}>{t('invitations:overview.discounts.noDiscounts')}</p>
        ) : (
          <>
            <div {...stylex.props(styles.commissionSaved)}>
              <Text weight="medium">
                <Trans
                  components={{ bold: <Span weight="bold" /> }}
                  i18nKey="invitations:overview.discounts.commissionSaved"
                  values={{ amount: formatCurrency(referralProfit) }}
                />
              </Text>
            </div>

            {activeDiscounts.length > 0 && (
              <>
                <Text styles={styles.discountType} weight="medium">
                  {t('invitations:overview.discounts.subtitleActive')}
                </Text>

                <ul {...stylex.props(styles.discounts)}>
                  {activeDiscounts.map((discount) => (
                    <li {...stylex.props(styles.discount)} key={discount.id}>
                      <Icon name="Sell" size={18} styles={styles.icon} />
                      <Text styles={styles.inviteeName}>{discount.inviteeName}</Text>
                      <Text size="small" styles={styles.date}>
                        {t('invitations:overview.discounts.startedAt', { startDate: formatDate(discount.validFrom) })}
                      </Text>
                    </li>
                  ))}

                  {Array.from({ length: 4 - activeDiscounts.length })
                    .fill(null)
                    .map((_, i) => (
                      <li {...stylex.props(styles.discount)} key={i}>
                        <Icon name="Sell" size={18} styles={[styles.icon, styles.iconMuted]} />
                        <Button onClick={sendInvitationDialog.open} styles={styles.inviteAnotherUserButton} variant="link">
                          {t('invitations:overview.discounts.inviteButton')}
                        </Button>
                      </li>
                    ))}
                </ul>
              </>
            )}

            {activeDiscounts.length > 0 && expiredDiscounts.length > 0 && <Separator spacing="normal" />}

            {expiredDiscounts.length > 0 && (
              <>
                <Text styles={styles.discountType} weight="medium">
                  {t('invitations:overview.discounts.subtitleExpired')}
                </Text>

                <ul {...stylex.props(styles.discounts)}>
                  {expiredDiscounts.map((discount) => (
                    <li {...stylex.props(styles.discount)} key={discount.id}>
                      <Icon name="Sell" size={18} styles={[styles.icon, styles.iconInvisible]} />
                      <Text styles={styles.inviteeName}>{discount.inviteeName}</Text>
                      <Text size="small" styles={styles.date}>
                        {t('invitations:overview.discounts.endedAt', { endDate: formatDate(discount.validUntil) })}
                      </Text>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </article>

      <SendInvitationDialog isOpen={sendInvitationDialog.isOpen} onClose={sendInvitationDialog.close} />
    </>
  );
};
