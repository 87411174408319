export const validation = {
  client: {
    emails: {
      eachTypeSelected: 'Specify at least one e-mail address for each type of e-mail',
      invalid: 'Only enter valid email addresses.',
      noEmptySelection: 'Select at least one type of e-mail per e-mail address',
      required: 'Enter at least one email address.',
    },
  },
  dateTomorrow: 'The expiration date must be at least tomorrow.',
  email: {
    invalid: 'Please enter a valid email address.',
  },
  iban: 'Enter a valid IBAN number.',
  integrations: {
    accountantEmailInvalid: 'Your accountant does not yet have an account on our Credit platform.',
  },
  invoice: {
    lines: {
      minimumOne: 'Add at least one invoicing line to the invoice.',
      minimumOneNotFree: 'Add at least one invoicing line that is not free.',
    },
  },
  max: '{{attribute}} cannot exceed {{max}}.',
  maxDecimals: '{{attribute}} may not exceed {{max}} are numbers after the decimal point.',
  maxDiscount: 'The discount cannot exceed the total price.',
  maxFileSize: 'The file size should not exceed {{max}} MB.',
  maxLength: '{{attribute}} cannot exceed {{max}} characters.',
  maxLengthInLanguage: '{{attribute}} cannot be greater than {{max}} characters ({{lang}}).',
  min: '{{attribute}} should not be smaller than {{min}}.',
  minLength: '{{attribute}} must exceed {{min}} characters.',
  password: {
    invalid: 'This password must be identical to the new password.',
  },
  phoneNumber: {
    format: 'Please enter a valid telephone number.',
    missingCallingCode: 'The phone number must start with a country code, for example +32 in Belgium.',
  },
  quotation: {
    entrynumber: {
      invalid: 'This quotation number was already used for a previous quotation.',
    },
    lines: {
      minimumOne: 'Add at least one quotation line to the quotation.',
      minimumOneNotFree: 'Add at least one quotation line that is not free.',
    },
  },
  required: 'This field is mandatory.',
  requiredAttribute: '{{attribute}} is mandatory.',
  requiredInLanguage: 'This field is mandatory ({{lang}}).',
  signContract: {
    message: 'Enter “Read and Approved”',
  },
  vatNumber: {
    belgianFormat: 'Please enter a valid company number. It consists of 10 digits and starts with 0 or 1.',
    inactive: 'This organization is not active.',
    invalid: 'The VAT number is invalid, can you request it again?',
    unique: "You've already created a client with this VAT number. <0>Click here </0> to add a new contact person to this customer. </0>",
  },
};
