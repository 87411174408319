import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useCreateInvoice, useInvoices } from '~/api/invoices';
import { useCreateQuotation, useQuotations } from '~/api/quotations';
import { useUser } from '~/api/user';
import { Announcement, Button, DropdownButton, PageHeader } from '~/components';
import { useIntl } from '~/hooks/useIntl';
import { useModal } from '~/hooks/useModal';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { useScroll } from '~/hooks/useScroll';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { getGreetingMessage } from '~/utils/dates';
import { getActiveCommissionBracket } from '~/utils/user';

import { AccountCompletion } from './AccountCompletion/AccountCompletion';
import { ContractModal } from './AccountCompletion/ContractModal/ContractModal';
import { Charts } from './Charts/Charts';
import { StatsSummary } from './StatsSummary/StatsSummary';

export const Dashboard = () => {
  const { data: user } = useUser();
  const { data: invoices } = useInvoices();
  const { data: quotations } = useQuotations();
  const createInvoiceMutation = useCreateInvoice();
  const createQuotationMutation = useCreateQuotation();
  // const updateAppSettingsMutation = useUpdateAppSettings();

  const contractModal = useModal();

  const navigate = useNavigate();
  const { formatDate, formatPercentage } = useIntl();
  const { t } = useTranslation(['common', 'dashboard']);
  const screen = useScreenWidth();
  const { isTop } = useScroll();

  const hasInvoicesThatCanBeCredited = invoices.some((invoice) => invoice.allowed && !invoice.paid && !invoice.credited);

  const createDraftInvoice = () => {
    createInvoiceMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  const createDraftQuotation = () => {
    createQuotationMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createQuotation, { quotationId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  // TODO: enable closing 2025 announcement
  // const closeAnnouncement = () => updateAppSettingsMutation.mutate({ appSettings: { show2025Announcement: false } });

  return (
    <>
      <PageHeader
        description={t('dashboard:header.description')}
        title={!screen.isMobile && !isTop ? t('common:sidebar.dashboard') : `${getGreetingMessage(new Date())} ${user.firstName}`}
      >
        <DropdownButton
          data-pf-id="dashboard-header-create-invoice"
          hasSpinner
          icon="RequestQuote"
          isLoading={createInvoiceMutation.isPending || createQuotationMutation.isPending}
          items={[
            {
              text: t('dashboard:header.actionBlock.dropdown.quotation'),
              onClick: createDraftQuotation,
              disabled: createQuotationMutation.isPending,
              'data-pf-id': 'dashboard-header-create-quotation',
            },
            ...insertIf(hasInvoicesThatCanBeCredited, {
              text: t('dashboard:header.actionBlock.dropdown.creditnote'),
              onClick: () => navigate(routes.createCreditnote),
              'data-pf-id': 'dashboard-header-create-credit-note',
            }),
            {
              text: t('dashboard:header.actionBlock.dropdown.client'),
              onClick: () => navigate(routes.createClient),
              'data-pf-id': 'dashboard-header-create-client',
            },
          ]}
          onClick={createDraftInvoice}
          text={t('dashboard:header.actionBlock.button')}
        />
      </PageHeader>

      {user.appSettings.show2025Announcement && (
        <div className="-mt-4 mb-6">
          <Announcement
            /* onClose={closeAnnouncement} */
            title={t(`dashboard:announcements.reform2025.${user.appSettings.communicationGroup2025}.title`)}
          >
            <Trans
              components={{
                faqLink: (
                  <a
                    className="[&>span]:text-secondary-500 underline"
                    href="https://www.creativeshelter.be/blogs/auteursrechten-overgangsregeling"
                    rel="noreferrer noopener"
                    target="_blank"
                  />
                ),
              }}
              i18nKey={`dashboard:announcements.reform2025.${user.appSettings.communicationGroup2025}.description`}
              values={{
                commissionPercentage: formatPercentage(
                  'creativePercentage' in user.commissionSettings
                    ? user.commissionSettings.creativePercentage / 100
                    : user.commissionSettings.creativePercentages[getActiveCommissionBracket(user)] / 100,
                ),
                endOfCommissionYear: formatDate(
                  dayjs(user.commissionHistoryStart ?? new Date())
                    .year(2025)
                    .toDate(),
                ),
              }}
            />

            {!user.addendumSigned && (
              <div className="mt-4 flex flex-wrap lg:flex-nowrap items-center gap-x-8 gap-y-4">
                <p>
                  <Trans i18nKey="dashboard:announcements.reform2025.cta" />
                </p>
                <Button icon="ChevronRight" onClick={contractModal.open}>
                  {t('dashboard:announcements.reform2025.ctaButton')}
                </Button>
              </div>
            )}
          </Announcement>
        </div>
      )}

      {/* Account completion */}
      <AccountCompletion />

      {/* Stats summary */}
      <StatsSummary invoices={invoices} quotations={quotations} />

      {/* Charts */}
      <Charts invoices={invoices} user={user} />

      {/* Sign contract modal */}
      {contractModal.isOpen && <ContractModal onClose={contractModal.close} />}
    </>
  );
};
