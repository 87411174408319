import type { ReactNode } from 'react';

import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Redirect } from '~/components/Functional';
import { LoadingScreen } from '~/components/UI';
import { getAccessToken } from '~/utils/auth';

import { routes } from './router.routes';

type WithChildren = { children: ReactNode };

/**
 * Redirect to the login screen if the user is not authenticated
 */
export const AuthGuard = ({ children }: WithChildren) => {
  const accessToken = getAccessToken();
  const isAuthenticated = accessToken !== '';

  const { hash, pathname, search } = useLocation();

  return isAuthenticated ? children : <Redirect state={{ redirectPath: `${pathname}${search}${hash}` }} to={routes.login} />;
};

/**
 * Redirect to the dashboard if the user is already authenticated
 */
export const NoAuthGuard = ({ children }: WithChildren) => {
  const accessToken = getAccessToken();
  const isAuthenticated = accessToken !== '';

  return isAuthenticated ? <Redirect to={routes.dashboard} /> : children;
};

/**
 * Suspend until the route's loader promise data is resolved.
 * The route loader ensures all necessary query data is fetched and cached.
 */
export const QueryDataGuard = ({ children }: WithChildren) => {
  const { t } = useTranslation(['common']);

  return <Suspense fallback={<LoadingScreen>{t('common:loading')}</LoadingScreen>}>{children}</Suspense>;
};
