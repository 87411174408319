import * as stylex from '@stylexjs/stylex';

import { Icon } from '~/components/SVG';
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/UI';

import { styles } from './PricingInfoTag.styles';

type Props = {
  label: string;
  tooltip?: string;
  variant: 'success' | 'warning';
};

export const PricingInfoTag = ({ label, tooltip, variant }: Props) => {
  const tagStyles = [styles.tag, variant === 'success' && styles.tagSuccess, variant === 'warning' && styles.tagWarning];

  if (!tooltip) {
    return <div {...stylex.props(...tagStyles)}>{label}</div>;
  }

  return (
    <Tooltip>
      <TooltipTrigger focusable styles={styles.tooltipTrigger}>
        <div {...stylex.props(...tagStyles)}>
          {label}

          <Icon
            name="HelpOutline"
            size={14}
            styles={[variant === 'success' && styles.iconSuccess, variant === 'warning' && styles.iconWarning]}
          />
        </div>
      </TooltipTrigger>

      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};
