export const pricing = {
  pageHeader: {
    description: 'Check hier je vergoeding en je voordeel.',
    title: 'Prijzen',
  },
  pricingAdvantage: {
    advantageCurrentYear: 'Jouw voorlopige <colored>voordeel</colored> dit jaar.',
    advantageLastYear: 'Jouw totale <colored>voordeel</colored> vorig jaar.',
    disclaimer_one:
      'Dit bedrag is de som van de voordelen van al je betaalde facturen. De simulatie is een schatting, jouw specifiek voordeel kan afwijken afhankelijk van je situatie.',
    disclaimer_other:
      'Deze bedragen zijn de som van de voordelen van al je betaalde facturen. De simulatie is een schatting, jouw specifiek voordeel kan afwijken afhankelijk van je situatie.',
  },
  pricingFeatures: {
    group1: {
      feature1: {
        label: 'Facturen en offertes opmaken',
      },
      feature2: {
        label: 'Overzichtelijk beheer van je klanten',
      },
      feature3: {
        label: 'Een persoonlijk dashboard',
      },
      feature4: {
        label: 'Een community',
      },
      title: 'Ons platform',
    },
    group2: {
      feature1: {
        label: 'Aangifte roerende voorheffing',
      },
      feature2: {
        label: 'Verplichte fiscale fiches? Stellen wij op!',
      },
      feature3: {
        label: 'Een extra paar ogen voor je contracten',
      },
      feature4: {
        label: 'Opvolging voor je onbetaalde facturen',
      },
      title: 'Administratief ontzorgd',
    },
    group3: {
      feature1: {
        label: 'Advies op maat',
      },
      feature2: {
        label: 'Persoonlijke dubbelcheck van je factuur',
      },
      feature3: {
        label: 'Technische ondersteuning',
      },
      feature4: {
        label: 'Een menselijke en persoonlijke opvolging',
      },
      title: 'Een service waarop je kan rekenen',
    },
    title: 'Features',
  },
  pricingInfo: {
    commission: {
      details: {
        button: 'Details',
        commissionWithDiscount: 'Nieuwe vergoeding',
        commissionWithoutDiscount: 'Standaardvergoeding',
        freeTrial_one: '{{count}} dag gratis',
        freeTrial_other: '{{count}} dagen gratis',
        halfPercentDiscount: 'Promo',
        referralDiscount: 'Referral {{inviteeName}}',
      },
      deviations: {
        adminOverride: {
          explanation: 'Je vergoeding wijkt af van het standaardtarief.',
          label: 'Aangepaste vergoeding',
        },
        earlyAdopter: {
          label: 'Early adopter',
        },
        halfPercentDiscount: {
          label: '0,5% korting',
          tooltip: 'Geldig tot {{expirationDate}}',
        },
        loyalUser: {
          label: 'Trouwe klant',
          tooltip: 'Omdat je voor 2025 al klant was, hebben we je een lagere vergoeding gegeven dan onze standaardvergoeding van 8%.',
        },
        premiumForFree: {
          label_one: 'Nog {{count}} dag gratis',
          label_other: 'Nog {{count}} dagen gratis',
          tooltip: 'Geldig tot {{expirationDate}}',
        },
        referralDiscount: {
          label: 'Referral korting ({{amount}})',
        },
        yearlyCopyrightLimitExceeded: {
          explanation:
            'Je hebt jouw limiet van auteursrechten voor dit jaar overschreden. Al je prestaties zijn nu beroepsinkomsten, dus mag je gratis factureren tot het einde van het jaar.',
          label: 'Limiet auteursrechten bereikt',
        },
      },
      explanation:
        'Dit is wat Creative Shelter inhoudt op het totaalbedrag van je factuur (excl. btw), per creatieve prestatie. Je betaalt alleen als je factureert. Geen factuur? Geen kosten.',
      percentage: '<large>{{percentage}}</large> vergoeding',
    },
    description:
      'Jouw vergoeding wordt berekend per factuur, dat is maar fair. Geen verborgen abonnement of minimum aantal factureren. Je hangt aan niets vast.<break/>En ja, ook na die vergoeding hou je netto tot 20% meer over.',
    title: 'Een vaste vergoeding. Netto meer voordelen.',
  },
};
