import * as stylex from '@stylexjs/stylex';

import { animationSpeed, brandColors, fontSize, neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const isFocused = ':is([data-focus])';
const isClosed = ':is([data-closed])';
const isEntering = ':is([data-enter])';
const isLeaving = ':is([data-leave])';

export const styles = stylex.create({
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xsmall,
  },

  icon: {
    transitionProperty: 'transform',
    transitionDuration: animationSpeed.veryFast,
  },

  iconOpen: {
    transform: 'rotate(180deg)',
  },

  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: systemColors.borderDark,
    borderRadius: radius.xxxsmall,
    backgroundColor: neutralColors.white,
    outlineStyle: 'none',
    transformOrigin: 'top right',
    transform: {
      default: 'scale(100%)',
      [isClosed]: 'scale(95%)',
    },
    opacity: {
      default: 1,
      [isClosed]: 0,
    },
    transitionProperty: 'transform, opacity',
    transitionTimingFunction: 'ease-out',
    transitionDuration: {
      [isEntering]: animationSpeed.veryFast,
      [isLeaving]: animationSpeed.fast,
    },
  },

  item: {
    marginBlockStart: 1,
    paddingInline: spacing.small,
    paddingBlock: spacing.small,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xsmall,
    fontSize: fontSize.small,
    color: {
      default: systemColors.textBlack,
      [isFocused]: brandColors.primary500,
    },
    backgroundColor: {
      default: null,
      [isFocused]: brandColors.primary050,
    },
    transitionProperty: 'color, background-color',
    transitionDuration: animationSpeed.veryFast,
    position: 'relative',
    '::after': {
      content: '',
      position: 'absolute',
      bottom: -1,
      insetInline: spacing.small,
      borderBlockEndWidth: 1,
      borderColor: systemColors.borderLight,
      zIndex: 1,
    },
  },
});
