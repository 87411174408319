import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '~/components/types';

import { colorStyles, sizeStyles, styles, weightStyles, wrapStyles } from './Text.styles';

type Props = WithoutStyleProps<{
  children?: ReactNode;
  color?: 'inherit' | 'black' | 'dark' | 'muted';
  italic?: boolean;
  noLineHeight?: boolean;
  size?: 'tiny' | 'small' | 'regular' | 'large' | 'xlarge';
  styles?: stylex.StyleXStyles;
  weight?: 'light' | 'regular' | 'medium' | 'semiBold' | 'bold';
  wrap?: 'wrap' | 'balance' | 'pretty';
}>;

export const Text = ({
  children,
  color = 'inherit',
  italic = false,
  noLineHeight = false,
  size = 'regular',
  styles: styleOverrides,
  weight = 'regular',
  wrap = 'wrap',
}: Props) => {
  return (
    <p
      {...stylex.props(
        colorStyles[color],
        sizeStyles[size],
        weightStyles[weight],
        wrapStyles[wrap],
        italic && styles.italic,
        noLineHeight && styles.noLineHeight,
        styleOverrides,
      )}
    >
      {children}
    </p>
  );
};
