import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './Span.styles';
import { colorStyles, sizeStyles, styles as textStyles, weightStyles } from './Text.styles';

type Props = {
  children?: ReactNode;
  color?: 'black' | 'dark' | 'muted';
  italic?: boolean;
  noLineHeight?: boolean;
  size?: 'tiny' | 'small' | 'regular' | 'large' | 'xlarge';
  styles?: stylex.StyleXStyles;
  weight?: 'light' | 'regular' | 'medium' | 'semiBold' | 'bold';
};

export const Span = ({ children, color, italic = false, noLineHeight, size, styles: styleOverrides, weight }: Props) => {
  return (
    <span
      {...stylex.props(
        styles.span,
        color && colorStyles[color],
        size && sizeStyles[size],
        weight && weightStyles[weight],
        italic && textStyles.italic,
        noLineHeight && styles.noLineHeight,
        styleOverrides,
      )}
    >
      {children}
    </span>
  );
};
