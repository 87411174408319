import * as stylex from '@stylexjs/stylex';

import { animationSpeed, radius, systemColors } from '~/styles/tokens.stylex';

const outlineFadeIn = stylex.keyframes({
  '0%': {
    outlineColor: 'transparent',
    outlineOffset: '3px',
  },
  '100%': {
    outlineColor: 'currentColor',
    outlineOffset: '1px',
  },
});

export const styles = stylex.create({
  trigger: {
    borderRadius: radius.xxxsmall,
    outlineStyle: {
      default: 'none',
      ':focus-visible': 'solid',
    },
    outlineWidth: '1px',
    outlineColor: systemColors.outline,
    outlineOffset: '1px',
    animationName: {
      default: null,
      ':focus-visible': outlineFadeIn,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
  },
});
