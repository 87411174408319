import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useClients } from '~/api/clients';
import { useCreateInvoice, useInvoices } from '~/api/invoices';
import { useBillitSsoUrl, useUser } from '~/api/user';
import { CollapsibleSection, DropdownButton, FloatingActionButton, PageHeader, TabSection } from '~/components';
import { Carousel } from '~/components/Templates/InvoiceQuotation';
import { useModalWithData } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';

import { DeleteDraftInvoiceModal } from '../Modals/DeleteDraftInvoiceModal';
import { CreditNotesTable } from './CreditNotes/CreditNotesTable';
import { InvoicesTable } from './Invoices/InvoicesTable';

export const InvoicesCreditnotesOverview = () => {
  const { data: user } = useUser();
  const { data: invoices } = useInvoices();
  const { data: clients } = useClients();

  const createInvoiceMutation = useCreateInvoice();
  const getBillitSsoUrlMutation = useBillitSsoUrl();

  const deleteInvoiceModal = useModalWithData<number>();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'creditnotes', 'invoices']);

  const draftInvoices = useMemo(() => invoices.filter((invoice) => invoice.draft), [invoices]);

  const hasInvoicesThatCanBeCredited = invoices.some((invoice) => invoice.allowed && !invoice.paid && !invoice.credited);

  const createDraftInvoice = () => {
    createInvoiceMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  const createBillitInvoice = () => {
    if (!user.integrations.billit) return navigate(routes.settingsIntegrations);

    getBillitSsoUrlMutation.mutate(undefined, {
      onSuccess: (redirectUrl) => window.open(redirectUrl, '_blank')?.focus(),
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <main className="invoices">
      {/* Header */}
      <PageHeader description={t('invoices:header.description')} title={t('invoices:header.title')}>
        <DropdownButton
          data-pf-id="invoices-header-create-invoice"
          hasSpinner
          icon="RequestQuote"
          isLoading={createInvoiceMutation.isPending}
          items={[
            {
              text: t('invoices:header.actionBlock.dropdown.notCreative'),
              onClick: createBillitInvoice,
              'data-pf-id': 'invoices-header-create-billit-invoice',
            },
            ...insertIf(hasInvoicesThatCanBeCredited, {
              text: t('invoices:header.actionBlock.dropdown.creditnote'),
              onClick: () => navigate(routes.createCreditnote),
              'data-pf-id': 'invoices-header-create-credit-note',
            }),
          ]}
          onClick={createDraftInvoice}
          text={t('invoices:header.actionBlock.button')}
        />
      </PageHeader>

      {/* Drafts */}
      {draftInvoices.length > 0 && (
        <CollapsibleSection title={t('common:draft.overviewTitle')}>
          <Carousel
            slides={draftInvoices.map((invoice) => ({
              id: invoice.id,
              title: invoice.title,
              brandColor: user.brands.find(({ id }) => id === invoice.brandId)?.color ?? null,
              client: clients.filter(({ deleted }) => !deleted).find(({ id }) => id === invoice.clientId) ?? null,
              contact:
                clients
                  .filter(({ deleted }) => !deleted)
                  .flatMap(({ contacts }) => contacts)
                  .filter(({ deleted }) => !deleted)
                  .find(({ id }) => id === invoice.contactId) ?? null,
              editAction: (invoiceId) => navigate(r(routes.createInvoice, { invoiceId })),
              deleteAction: (invoiceId) => deleteInvoiceModal.open(invoiceId),
            }))}
          />
        </CollapsibleSection>
      )}

      {/* Invoices & credit notes */}
      <TabSection
        tabs={[
          {
            title: t('invoices:overview.created.subtitle'),
            component: <InvoicesTable />,
          },
          {
            title: t('creditnotes:overview.created.subtitle'),
            component: <CreditNotesTable />,
          },
        ]}
      />

      {/* Delete draft modal */}
      {deleteInvoiceModal.isOpen && <DeleteDraftInvoiceModal invoiceId={deleteInvoiceModal.data} onClose={deleteInvoiceModal.close} />}

      {/* FAB */}
      <FloatingActionButton
        options={[
          {
            text: t('invoices:header.actionBlock.dropdown.creditnote'),
            onClick: () => navigate(routes.createCreditnote),
          },
          {
            text: t('invoices:header.actionBlock.dropdown.notCreative'),
            onClick: createBillitInvoice,
          },
          {
            text: t('invoices:header.actionBlock.buttonMobile'),
            onClick: createDraftInvoice,
          },
        ]}
      />
    </main>
  );
};
