import dayjs from 'dayjs';

import type { EarlyAdopterPlanId } from '~/constants/plans.types';
import type { InviteeDiscount } from '~/types/user';

import { planIds } from '~/constants/plans';

import type { UserWithFixedPercentage } from './types';

type CommissionDeviationReasons =
  | 'loyalUser'
  | 'earlyAdopter'
  | 'freeTrial'
  | 'premiumForClassic'
  | 'referralDiscount'
  | 'yearlyCopyrightLimitExceeded'
  | 'adminOverride';

const getCommissionDeviationReasons = (user: UserWithFixedPercentage): Set<CommissionDeviationReasons> => {
  const commissionDeviationReasons = new Set<CommissionDeviationReasons>();

  if (dayjs(user.commissionHistoryStart).isBefore(dayjs('2025-01-01'))) commissionDeviationReasons.add('loyalUser');
  if (user.planId === planIds.earlyAdopter) commissionDeviationReasons.add('earlyAdopter');
  if (user.inviteeDiscount?.type === 'ShelterForFree') commissionDeviationReasons.add('freeTrial');
  if (user.inviteeDiscount?.type === 'PremiumForClassic') commissionDeviationReasons.add('premiumForClassic');
  if (user.invitorDiscounts.filter(({ isActive }) => isActive).length > 0) commissionDeviationReasons.add('referralDiscount');
  if (user.yearlyCopyrightLimitExceeded) commissionDeviationReasons.add('yearlyCopyrightLimitExceeded');
  if (user.commissionSettings.adminOverride) commissionDeviationReasons.add('adminOverride');

  // Remove certain deviation reasons to prevent redundant or illogical combinations

  if (commissionDeviationReasons.has('earlyAdopter')) {
    commissionDeviationReasons.delete('loyalUser');
  }

  if (commissionDeviationReasons.has('freeTrial')) {
    commissionDeviationReasons.delete('referralDiscount');
    commissionDeviationReasons.delete('yearlyCopyrightLimitExceeded');
    commissionDeviationReasons.delete('adminOverride');
  }

  if (commissionDeviationReasons.has('yearlyCopyrightLimitExceeded')) {
    commissionDeviationReasons.delete('referralDiscount');
    commissionDeviationReasons.delete('adminOverride');
  }

  return commissionDeviationReasons;
};

export const showLoyalUserTag = (user: UserWithFixedPercentage) => getCommissionDeviationReasons(user).has('loyalUser');

export const showEarlyAdopterTag = (user: UserWithFixedPercentage): user is UserWithFixedPercentage & { planId: EarlyAdopterPlanId } =>
  getCommissionDeviationReasons(user).has('earlyAdopter');

export const showFreeTrialTag = (
  user: UserWithFixedPercentage,
): user is UserWithFixedPercentage & { inviteeDiscount: InviteeDiscount & { type: 'ShelterForFree' } } =>
  getCommissionDeviationReasons(user).has('freeTrial');

export const showReferralDiscountTag = (user: UserWithFixedPercentage) => getCommissionDeviationReasons(user).has('referralDiscount');

export const showPremiumForClassicTag = (
  user: UserWithFixedPercentage,
): user is UserWithFixedPercentage & { inviteeDiscount: InviteeDiscount & { type: 'PremiumForClassic' } } =>
  getCommissionDeviationReasons(user).has('premiumForClassic');

export const showYearlyCopyrightLimitExceededTag = (user: UserWithFixedPercentage) =>
  getCommissionDeviationReasons(user).has('yearlyCopyrightLimitExceeded');

export const showAdminOverrideTag = (user: UserWithFixedPercentage) => getCommissionDeviationReasons(user).has('adminOverride');
