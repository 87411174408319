import * as stylex from '@stylexjs/stylex';

import type { MediaQueryDesktop, MediaQueryLaptopAndBelow } from '~/styles/mediaQueries';

import { animationSpeed, brandColors, fontWeight, neutralColors, radius, spacing } from '~/styles/tokens.stylex';

const Desktop: MediaQueryDesktop = '@media (1280px <= width < 1920px)';

const LaptopAndBelow: MediaQueryLaptopAndBelow = '@media (width < 1280px)';

const outlineFadeIn = stylex.keyframes({
  '0%': {
    outlineColor: 'transparent',
    outlineOffset: '-4px',
  },
  '100%': {
    outlineColor: 'currentColor',
    outlineOffset: '-6px',
  },
});

export const styles = stylex.create({
  logoLink: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: radius.xxsmall,
    color: {
      default: neutralColors.white,
      ':hover': brandColors.primary200,
    },
    transitionProperty: 'color',
    transitionDuration: animationSpeed.fast,
    outlineOffset: '-6px',
    animationName: {
      default: null,
      ':focus-visible': outlineFadeIn,
    },
  },

  logo: {
    maxWidth: 224,
    height: 80,
    paddingInline: {
      default: spacing.normal,
      [LaptopAndBelow]: spacing.small,
    },
    transitionProperty: 'padding',
    transitionDuration: animationSpeed.fast,
  },

  logoCollapsed: {
    maxWidth: 96,
    height: 112,
    paddingInline: spacing.small,
  },

  invitationButtonWrapper: {
    marginInline: 'auto',
    paddingInline: spacing.xsmall,
    paddingBlock: {
      default: spacing.large,
      [Desktop]: spacing.normal,
      [LaptopAndBelow]: spacing.xxsmall,
    },
  },

  invitationButton: {
    position: 'relative',
    minHeight: 40,
    marginInlineStart: spacing.medium,
    paddingInline: spacing.xsmall,
    color: neutralColors.gray800,
    borderColor: {
      default: brandColors.secondary500,
      ':hover': neutralColors.gray800,
    },
    backgroundColor: {
      default: brandColors.secondary500,
      ':hover': neutralColors.white,
    },
    fontWeight: fontWeight.medium,
  },

  invitationButtonCollapsed: {
    marginInlineStart: spacing.none,
    paddingInlineStart: spacing.none,
    paddingInlineEnd: spacing.none,
    width: 60,
  },

  invitationIllustration: {
    position: 'absolute',
    marginBlock: 'auto',
    bottom: -8,
    left: -42,
    height: 48,
  },

  invitationIllustrationCollapsed: {
    bottom: 'unset',
    left: 'unset',
    inset: 0,
    margin: 'auto',
    height: 38,
    transform: 'unset',
  },

  invitationButtonTextCollapsed: {
    display: 'none',
  },
});
