import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { Fragment, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { Icon } from '~/components/SVG';
import { Button, Separator, Span, Text, Title } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';

import type { UserWithFixedPercentage } from './types';

import { styles } from './PricingInfo.styles';
import {
  showAdminOverrideTag,
  showEarlyAdopterTag,
  showFreeTrialTag,
  showLoyalUserTag,
  showPremiumForClassicTag,
  showReferralDiscountTag,
  showYearlyCopyrightLimitExceededTag,
} from './PricingInfo.utils';
import { PricingInfoTag } from './PricingInfoTag';

type Props = {
  user: UserWithFixedPercentage;
};

export const PricingInfo = ({ user }: Props) => {
  const [showDetails, toggleDetails] = useReducer((show) => !show, false);

  const [ref] = useAutoAnimate();
  const { formatDate, formatPercentage } = useIntl();
  const { t } = useTranslation(['pricing']);

  const activeInvitorDiscounts = user.invitorDiscounts
    .filter(({ isActive }) => isActive)
    .sort(({ validUntil: a }, { validUntil: b }) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1));

  const totalDiscountPercentage = Math.min(
    activeInvitorDiscounts.reduce((totalPercentage, { percentage }) => (totalPercentage += percentage), 0),
    1,
  );
  const hasDiscount = user.inviteeDiscount !== null || activeInvitorDiscounts.length > 0;

  const commissionPercentage = user.yearlyCopyrightLimitExceeded ? 0 : user.commissionSettings.creativePercentage / 100;
  const commissionPercentageWithPromo = match(user.inviteeDiscount?.type)
    .with('ShelterForFree', () => 0)
    .with('PremiumForClassic', () => commissionPercentage - 0.005)
    .otherwise(() => commissionPercentage);
  const commissionPercentageWithDiscount = commissionPercentageWithPromo * (1 - totalDiscountPercentage);

  return (
    <section {...stylex.props(styles.wrapper)}>
      <div {...stylex.props(styles.infoWrapper)}>
        <div {...stylex.props(styles.heading)}>
          <Title as="h4">{t('pricing:pricingInfo.title')}</Title>
          <Text color="muted" weight="light" wrap="pretty">
            {[
              t('pricing:pricingFeatures.group1.title'),
              t('pricing:pricingFeatures.group2.title'),
              t('pricing:pricingFeatures.group3.title'),
            ].join(' · ')}
          </Text>
        </div>

        <Text styles={styles.description} wrap="pretty">
          <Trans components={{ break: <br /> }} i18nKey="pricing:pricingInfo.description" />
        </Text>
      </div>

      <article {...stylex.props(styles.commissionWrapper)} ref={ref}>
        <div {...stylex.props(styles.tags)}>
          {showLoyalUserTag(user) && (
            <PricingInfoTag
              label={t('pricing:pricingInfo.commission.deviations.loyalUser.label')}
              tooltip={t('pricing:pricingInfo.commission.deviations.loyalUser.tooltip')}
              variant="success"
            />
          )}

          {showEarlyAdopterTag(user) && (
            <PricingInfoTag label={t('pricing:pricingInfo.commission.deviations.earlyAdopter.label')} variant="success" />
          )}

          {showFreeTrialTag(user) && (
            <PricingInfoTag
              label={t('pricing:pricingInfo.commission.deviations.premiumForFree.label', {
                count: user.inviteeDiscount.daysActive,
              })}
              tooltip={t('pricing:pricingInfo.commission.deviations.premiumForFree.tooltip', {
                expirationDate: formatDate(
                  dayjs()
                    .add(user.inviteeDiscount.daysActive - 1, 'days')
                    .toDate(),
                ),
              })}
              variant="success"
            />
          )}

          {showPremiumForClassicTag(user) && (
            <PricingInfoTag
              label={t('pricing:pricingInfo.commission.deviations.halfPercentDiscount.label')}
              tooltip={t('pricing:pricingInfo.commission.deviations.halfPercentDiscount.tooltip', {
                expirationDate: formatDate(
                  dayjs()
                    .add(user.inviteeDiscount.daysActive - 1, 'days')
                    .toDate(),
                ),
              })}
              variant="success"
            />
          )}

          {showReferralDiscountTag(user) && (
            <PricingInfoTag
              label={t('pricing:pricingInfo.commission.deviations.referralDiscount.label', {
                amount: formatPercentage(-1 * totalDiscountPercentage),
              })}
              variant="success"
            />
          )}

          {showYearlyCopyrightLimitExceededTag(user) && (
            <PricingInfoTag label={t('pricing:pricingInfo.commission.deviations.yearlyCopyrightLimitExceeded.label')} variant="warning" />
          )}

          {showAdminOverrideTag(user) && (
            <PricingInfoTag label={t('pricing:pricingInfo.commission.deviations.adminOverride.label')} variant="warning" />
          )}
        </div>

        <header {...stylex.props(styles.commissionHeader)}>
          <Text color="black" size="large" weight="semiBold">
            <Trans
              components={{ large: <Span size="xlarge" weight="bold" /> }}
              i18nKey="pricing:pricingInfo.commission.percentage"
              values={{ percentage: formatPercentage(commissionPercentageWithDiscount) }}
            />
          </Text>

          {hasDiscount && (
            <Text color="muted" size="small">
              <Button onClick={toggleDetails} variant="ghost">
                <span {...stylex.props(styles.commissionDetailsButton)}>
                  {t('pricing:pricingInfo.commission.details.button')}
                  <Icon
                    name="KeyboardArrowDown"
                    size={20}
                    styles={[styles.commissionDetailsIcon, showDetails && styles.commissionDetailsIconOpen]}
                  />
                </span>
              </Button>
            </Text>
          )}
        </header>

        {showDetails && (
          <>
            <Separator spacing="xxsmall" />

            <div {...stylex.props(styles.commissionDetails)}>
              <Span size="small">{t('pricing:pricingInfo.commission.details.commissionWithoutDiscount')}</Span>
              <Span size="small" styles={styles.commissionDetailsPercentage} weight="medium">
                {formatPercentage(commissionPercentage)}
              </Span>

              {user.inviteeDiscount?.type === 'ShelterForFree' && (
                <>
                  <Span size="small">
                    {t('pricing:pricingInfo.commission.details.freeTrial', { count: user.inviteeDiscount.daysValid })}
                  </Span>
                  <Span size="small" styles={styles.commissionDetailsPercentage} weight="medium">
                    {formatPercentage(-1 * commissionPercentage)}
                  </Span>
                </>
              )}

              {user.inviteeDiscount?.type === 'PremiumForClassic' && (
                <>
                  <Span size="small">{t('pricing:pricingInfo.commission.details.halfPercentDiscount')}</Span>
                  <Span size="small" styles={styles.commissionDetailsPercentage} weight="medium">
                    {formatPercentage(-0.005)}
                  </Span>
                </>
              )}

              {commissionPercentageWithPromo > 0 &&
                activeInvitorDiscounts.map((discount) => (
                  <Fragment key={discount.id}>
                    <Span size="small">
                      {t('pricing:pricingInfo.commission.details.referralDiscount', { inviteeName: discount.inviteeName })}
                    </Span>
                    <Span size="small" styles={styles.commissionDetailsPercentage} weight="medium">
                      {formatPercentage(-1 * commissionPercentageWithPromo * discount.percentage)}
                    </Span>
                  </Fragment>
                ))}

              <Separator spacing="xxxsmall" styles={styles.commissionDetailsLine} />

              <Span size="small">{t('pricing:pricingInfo.commission.details.commissionWithDiscount')}</Span>
              <Span size="small" styles={styles.commissionDetailsPercentage} weight="medium">
                {formatPercentage(commissionPercentageWithDiscount)}
              </Span>
            </div>
          </>
        )}

        <Separator spacing="xxsmall" />

        <Text size="small">{t('pricingInfo.commission.explanation')}</Text>

        {(showYearlyCopyrightLimitExceededTag(user) || showAdminOverrideTag(user)) && (
          <>
            <Separator spacing="xxsmall" />

            {showYearlyCopyrightLimitExceededTag(user) && (
              <Text size="tiny" styles={styles.commissionDeviationWarning}>
                <Icon inline name="ErrorOutline" size={14} styles={styles.commissionDeviationWarningIcon} />
                {t('pricing:pricingInfo.commission.deviations.yearlyCopyrightLimitExceeded.explanation')}
              </Text>
            )}

            {showAdminOverrideTag(user) && (
              <Text size="tiny" styles={styles.commissionDeviationWarning}>
                <Icon inline name="ErrorOutline" size={14} styles={styles.commissionDeviationWarningIcon} />
                {t('pricing:pricingInfo.commission.deviations.adminOverride.explanation')}
              </Text>
            )}
          </>
        )}
      </article>
    </section>
  );
};
