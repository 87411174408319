import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { neutralColors, radius, spacing, statusColors, systemColors } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

export const styles = stylex.create({
  wrapper: {
    display: 'flex',
    flexDirection: {
      default: 'row',
      [TabletAndBelow]: 'column',
    },
    alignItems: 'baseline',
    columnGap: spacing.large,
    rowGap: spacing.normal,
  },

  infoWrapper: {
    flexGrow: 1,
    paddingBlock: spacing.normal,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
  },

  description: {
    maxWidth: '60ch',
  },

  commissionWrapper: {
    position: 'relative',
    maxWidth: {
      default: 400,
      [TabletAndBelow]: '100%',
    },
    padding: spacing.normal,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
    borderRadius: radius.xxsmall,
    borderWidth: 1,
    borderColor: systemColors.borderLight,
  },

  commissionHeader: {
    display: 'flex',
    gap: spacing.xxsmall,
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },

  commissionDetailsButton: {
    display: 'inline-flex',
    gap: spacing.xxxsmall,
    alignItems: 'center',
  },

  commissionDetailsIcon: {
    transitionProperty: 'transform',
    transitionDuration: '250ms', // Same as AutoAnimate
  },

  commissionDetailsIconOpen: {
    transform: 'rotate(180deg)',
  },

  commissionDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    rowGap: spacing.xxsmall,
    columnGap: spacing.xxsmall,
    padding: spacing.small,
    borderRadius: radius.xxsmall,
    backgroundColor: neutralColors.gray025,
  },

  commissionDetailsPercentage: {
    textAlign: 'right',
  },

  commissionDetailsLine: {
    gridColumn: '1 / -1',
  },

  tags: {
    position: 'absolute',
    top: -12, // Half of tag height (24px)
    right: spacing.xsmall,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
    gap: spacing.xxsmall,
  },

  commissionDeviationWarning: {
    color: statusColors.warningDark,
  },

  commissionDeviationWarningIcon: {
    marginInlineEnd: spacing.xxxsmall,
  },
});
