import { ArcElement, Chart } from 'chart.js';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useCallback, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

import type { CalculationData } from '~/api/common/types';

import { AnimateExpand } from '~/components';
import { useIntl } from '~/hooks/useIntl';
import { useRerender } from '~/hooks/useRerender';

import type { CalculationDataWithAdvantage, AdvantageSimulationChartProps as Props } from './types';

import styles from './AdvantageSimulationChart.module.scss';
import { useChartConfig, useChartData } from './hooks';

Chart.register(ArcElement);

export const AdvantageSimulationChart = ({ calculationData }: Props) => {
  assertCalculationDataHasAdvantage(calculationData);

  const ref = useRef<Chart<'doughnut', number[]>>(null);

  const [showDataWithCs, setShowDataWithCs] = useState(true);
  const { dataWithCs, dataWithoutCs } = useChartData(calculationData);
  const data = showDataWithCs ? dataWithCs : dataWithoutCs;
  const config = useChartConfig(data.map(({ value }) => value));

  const activeElement = ref.current?.getActiveElements()[0] ?? null;
  const activeSlice = activeElement ? data[activeElement.index] : null;

  const rerender = useRerender();
  const { formatCurrency, formatPercentageShort } = useIntl();
  const { t } = useTranslation(['invoices']);

  const onLabelClick = useCallback(
    (index: number) => {
      const chart = ref.current;

      if (!chart) return;

      if (chart.getActiveElements().some((activeElement) => activeElement.index === index)) {
        chart.setActiveElements([]);
      } else {
        chart.setActiveElements([{ datasetIndex: 0, index }]);
      }

      chart.update();

      rerender(); // Rerender is needed to update the UI
    },
    [rerender],
  );

  return (
    <div>
      <article className={styles.Totals}>
        <article>
          <p className={styles.Totals__Label}>{t('invoices:createEdit.advantageSimulationModal.totals.totalInvoiced')}</p>
          <p className={styles.Totals__Value}>{formatCurrency(calculationData.coExclVat)}</p>
        </article>

        <article>
          <p className={styles.Totals__Label}>{t('invoices:createEdit.advantageSimulationModal.totals.netAmount')}</p>
          <p className={styles.Totals__Value}>
            {formatCurrency(showDataWithCs ? calculationData.advantageWithCs.netAmount : calculationData.advantageWithoutCs.netAmount)}
          </p>
        </article>

        <article className={classNames(!showDataWithCs && styles.Invisible)}>
          <div>
            <p className={styles.Totals__Label}>{t('invoices:createEdit.advantageSimulationModal.totals.advantage')}</p>
            {calculationData.advantageWithCs.advantagePercentage > 0 ? (
              <div className={styles.Totals__ValueWrapper}>
                <p className={styles.Totals__Value}>{formatCurrency(calculationData.advantageWithCs.advantage)}</p>
                <span className={styles.Totals__PercentageTag}>
                  {formatPercentageShort(calculationData.advantageWithCs.advantagePercentage)}
                </span>
              </div>
            ) : (
              <span className={styles.Totals__NoAdvantageTag}>{t('invoices:createEdit.advantageSimulationModal.totals.noAdvantage')}</span>
            )}
          </div>
        </article>
      </article>

      <article className={styles.Chart} data-pf-id="advantage-chart">
        {/* Dedicated container for Doughnut chart. The chart must be the only child of this element. */}
        <div className={styles.Chart__Container}>
          <Doughnut
            data={config.data}
            options={{
              ...config.options,
              onClick: (event, elements, chart) => {
                const selectedElement = elements[0];

                if (!selectedElement || chart.getActiveElements().some((activeElement) => activeElement.index === selectedElement.index)) {
                  chart.setActiveElements([]);
                } else {
                  chart.setActiveElements([{ datasetIndex: 0, index: selectedElement.index }]);
                }

                rerender(); // Rerender is needed to update the UI
              },
            }}
            ref={ref}
          />
        </div>

        <ul className={styles.Legend}>
          <li className={styles.Legend__Label}>
            {showDataWithCs
              ? t('invoices:createEdit.advantageSimulationModal.label.withCs')
              : t('invoices:createEdit.advantageSimulationModal.label.withoutCs')}
          </li>

          {data.map(({ color, id, label, value }, i) => {
            if (value === 0) return null;

            return (
              <li
                className={classNames(
                  styles.Legend__Item,
                  !!ref.current && ref.current.getActiveElements()[0]?.index === i && styles.Active,
                )}
                key={id}
                onClick={() => onLabelClick(i)}
              >
                <span className={classNames(styles.LegendDot, color)} />
                <button>{label}</button>
              </li>
            );
          })}

          {/* Fill legend with invisible to prevent layout shift */}
          {data
            .filter(({ value }) => value === 0)
            .map(({ id, label }) => (
              <li className={classNames(styles.Legend__Item, styles.Invisible)} key={id}>
                <span className={styles.LegendDot} />
                <span>{label}</span>
              </li>
            ))}
        </ul>
      </article>

      <article className={styles.Toggle} data-pf-id="advantage-toggle-cs">
        <button className={classNames(styles.Toggle__Button, showDataWithCs && styles.Active)} onClick={() => setShowDataWithCs(true)}>
          {t('invoices:createEdit.advantageSimulationModal.toggle.withCs')}
        </button>
        <button className={classNames(styles.Toggle__Button, !showDataWithCs && styles.Active)} onClick={() => setShowDataWithCs(false)}>
          {t('invoices:createEdit.advantageSimulationModal.toggle.withoutCs')}
        </button>
      </article>

      <AnimateExpand>
        {activeSlice && activeSlice.value > 0 && (
          <article className={styles.Details}>
            <header className={styles.Details__Header}>
              <div className={styles.Details__TitleWrapper}>
                <span className={classNames(styles.LegendDot, activeSlice.color)} />
                <span className={styles.Details__Title}>{activeSlice.label}</span>
              </div>

              <span>{formatCurrency(activeSlice.value * (activeSlice.id === 'netAmount' ? 1 : -1))}</span>
            </header>

            <div className={styles.Details__Description}>
              <span>{parse(activeSlice.description)}</span>
              {activeSlice.disclaimer && '*'}
            </div>

            {activeSlice.disclaimer && <div className={styles.Details__Disclaimer}>*{parse(activeSlice.disclaimer)}</div>}
          </article>
        )}
      </AnimateExpand>
    </div>
  );
};

function assertCalculationDataHasAdvantage(calculationData: CalculationData): asserts calculationData is CalculationDataWithAdvantage {
  invariant(calculationData.advantageWithCs);
  invariant(calculationData.advantageWithoutCs);
}
