import { useUpdateAppSettings, useUser } from '~/api/user';
import { Icon } from '~/components/SVG';
import { ColorPicker } from '~/components/UI';
import { useActiveColorTheme } from '~/hooks/useAppColorTheme';

import { InviteeDiscountCountdown } from './InviteeDiscountCountdown';
import styles from './TopBar.module.scss';
import { TopBarMenu } from './TopBarMenu';

export const TopBar = () => {
  const { data: user } = useUser();
  const mutation = useUpdateAppSettings();

  const colorTheme = useActiveColorTheme();

  return (
    <div className={styles.TopBarWrapper}>
      <div className={styles.TopBar}>
        <InviteeDiscountCountdown discount={user.inviteeDiscount} />

        <a className={styles.Faq} href="https://knowledge.creativeshelter.be/" rel="noreferrer" target="_blank">
          <Icon name="Help" size={30} />
        </a>

        <ColorPicker
          onSelect={(colorTheme) => mutation.mutate({ appSettings: { theme: { ...user.appSettings.theme, appColor: colorTheme } } })}
          value={colorTheme}
        />

        <TopBarMenu />
      </div>
    </div>
  );
};
