import type { ComponentProps, ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import type { PartialBy } from '~/types/utils';

import { Icon } from '~/components/SVG';

import { styles } from './IconTooltip.styles';
import { Tooltip } from './Tooltip';
import { TooltipContent } from './TooltipContent';
import { TooltipTrigger } from './TooltipTrigger';

type Props = PartialBy<ComponentProps<typeof Icon>, 'name' | 'size'> & {
  children: ReactNode;
};

export const IconTooltip = ({ children, name = 'Help', size = 18, ...delegated }: Props) => {
  return (
    <Tooltip>
      <TooltipTrigger focusable>
        <span {...stylex.props(styles.iconWrapper)}>
          <Icon {...delegated} name={name} size={size} />
        </span>
      </TooltipTrigger>

      <TooltipContent>{children}</TooltipContent>
    </Tooltip>
  );
};
