import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { Button } from '~/components';
import { Icon } from '~/components/SVG';

import type { ExternalSystemCardProps as Props } from './types';

import styles from './ExternalSystems.module.scss';

export const ExternalSystemCard = ({ disabled = false, imageUrl, onPair, system }: Props) => {
  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'settings']);

  return (
    <li className={styles.system}>
      <img alt={t(`settings:integrations.externalSystems.systems.${system}.name`)} src={imageUrl} />

      <h3>
        {t('settings:integrations.externalSystems.pairWith', { system: t(`settings:integrations.externalSystems.systems.${system}.name`) })}
      </h3>

      <p className="mb-4">{t(`settings:integrations.externalSystems.systems.${system}.description`)}</p>

      {user.integrations[system] ? (
        <p className="mb-4 text-success-500 space-x-1 mt-auto">
          <Icon inline name="Check" />
          <span>{t('settings:integrations.externalSystems.paired')}</span>
        </p>
      ) : (
        <Button disabled={disabled} extraClasses={styles.pair} onClick={onPair}>
          {t('settings:integrations.pair')}
        </Button>
      )}
    </li>
  );
};
