import { useMemo } from 'react';

import type { CountryCode } from '~/api/common/types';

import { Language } from '~/types/app';

import { useCraftLocalStorage } from './useCraftLocalStorage';

/**
 * Collection of helper functions of the Intl namespace,
 * with automatic locale based on the authenticated user or the chosen app language.
 */
export const useIntl = (localeOverride?: Language) => {
  const [language] = useCraftLocalStorage('language');

  const locale = localeOverride ?? language ?? Language.DUTCH;

  // NumberFormat
  const decimalFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'decimal', maximumFractionDigits: 2 }), [locale]);
  const currencyFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }), [locale]);
  const shortCurrencyFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }), [locale]); // prettier-ignore
  const percentageFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'percent', maximumFractionDigits: 2 }), [locale]);
  const shortPercentageFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'percent' }), [locale]);

  // DateTimeFormat
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'long', year: 'numeric' }), [locale]);
  const shortDateFormatter = useMemo(() => new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'long' }), [locale]);

  // RelativeTimeFormat
  const relativeTimeFormat = useMemo(() => new Intl.RelativeTimeFormat(locale, { numeric: 'auto', style: 'long' }), [locale]);

  // ListFormat
  const conjunctiveListFormatter = useMemo(() => new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' }), [locale]);

  // Collator
  const sortCollator = useMemo(() => new Intl.Collator(locale, { usage: 'sort' }), [locale]);

  // DisplayNames
  const regionDisplayNames = useMemo(() => new Intl.DisplayNames(locale, { type: 'region', fallback: 'code' }), [locale]);

  return useMemo(
    () => ({
      formatDecimal: (...args: Parameters<typeof decimalFormatter.format>) => decimalFormatter.format(...args),
      formatCurrency: (...args: Parameters<typeof currencyFormatter.format>) => currencyFormatter.format(...args),
      formatCurrencyShort: (...args: Parameters<typeof shortCurrencyFormatter.format>) => shortCurrencyFormatter.format(...args),
      formatPercentage: (...args: Parameters<typeof percentageFormatter.format>) => percentageFormatter.format(...args),
      formatPercentageShort: (...args: Parameters<typeof shortPercentageFormatter.format>) => shortPercentageFormatter.format(...args),
      formatDate: (date: string | Date) => dateFormatter.format(new Date(date)),
      formatDateShort: (date: string | Date) => shortDateFormatter.format(new Date(date)),
      formatRelativeTime: (...args: Parameters<typeof relativeTimeFormat.format>) => relativeTimeFormat.format(...args),
      formatConjunctiveList: (...args: Parameters<typeof conjunctiveListFormatter.format>) => conjunctiveListFormatter.format(...args),
      compareForSort: (...args: Parameters<typeof sortCollator.compare>) => sortCollator.compare(...args),
      displayNameOfCountry: (countryCode: CountryCode) => regionDisplayNames.of(countryCode) ?? '',
    }),
    [
      conjunctiveListFormatter,
      currencyFormatter,
      dateFormatter,
      decimalFormatter,
      percentageFormatter,
      regionDisplayNames,
      relativeTimeFormat,
      shortCurrencyFormatter,
      shortDateFormatter,
      shortPercentageFormatter,
      sortCollator,
    ],
  );
};
