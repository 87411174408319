import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../SVG.types';

import { styles } from './Logo.styles';

export const LogoVertical = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg {...stylex.props(styles.logo, styleOverrides)} viewBox="0 0 511.85 383.65" xmlns="http://www.w3.org/2000/svg" {...delegated}>
      <path d="M0 358.78h18.4c.43 4.89 5.89 8.48 12.51 8.48s10.49-2.59 10.49-6.47c0-12.79-39.96-.43-39.96-28.89 0-11.79 10.49-21.7 27.6-21.7 15.38 0 28.6 8.19 30.33 23.14h-19.4c-.58-4.03-5.17-7.19-10.64-7.19-5.89 0-9.34 2.3-9.34 5.75 0 12.22 40.1-2.3 40.1 28.89 0 12.94-12.07 22.86-27.89 22.86-18.25 0-30.76-9.34-32.2-24.87m137.99-16.53v39.96h-20.27v-37.37c0-9.2-5.61-15.24-13.94-15.24s-13.94 6.04-13.94 15.24v37.37H69.57V281.59h20.27v39.24c4.46-6.76 11.07-10.64 19.12-10.64 18.11 0 29.04 12.07 29.04 32.05Zm79.35 9.49h-52.47c1.44 9.2 8.48 15.09 17.97 15.09 7.91 0 12.94-3.45 14.09-8.34h19.84c-2.3 15.24-15.67 25.16-33.35 25.16-20.84 0-37.08-16.24-37.08-37.09s16.1-36.37 36.65-36.37c19.12 0 34.78 14.95 34.78 34.35 0 1.58-.14 4.89-.43 7.19Zm-20.7-12.94c-.57-7.19-7.04-12.22-14.52-12.22s-13.8 3.88-16.24 12.22zm48.73 43.41H225.1V281.59h20.27zm39.1-51.32v22.28c0 7.62 5.17 10.92 12.22 10.92 1.72 0 3.59-.14 5.17-.58v18.69c-2.73.72-6.9 1.15-9.77 1.15-16.67 0-27.89-10.92-27.89-29.9v-22.57h-13.08v-19.26h13.08v-18.54h20.27v18.54h17.39v19.26h-17.39Zm90.7 20.84H322.7c1.44 9.2 8.48 15.09 17.97 15.09 7.91 0 12.94-3.45 14.09-8.34h19.84c-2.3 15.24-15.67 25.16-33.35 25.16-20.84 0-37.08-16.24-37.08-37.09s16.1-36.37 36.65-36.37c19.12 0 34.78 14.95 34.78 34.35 0 1.58-.14 4.89-.43 7.19Zm-20.7-12.94c-.57-7.19-7.04-12.22-14.52-12.22s-13.8 3.88-16.24 12.22zm74.89-27.74v18.4c-2.44-.58-4.89-1.01-7.33-1.01-9.77 0-17.68 5.89-17.68 18.97v34.79h-20.27v-70.58h20.27v8.05c4.17-6.04 10.35-9.49 18.25-9.49 2.16 0 4.46.43 6.76.86Z" />
      <path d="M.5 225.51c0-20.99 15.82-36.81 36.67-36.81 17.69 0 32.64 12.08 34.51 28.33H51.12c-1.44-5.18-7.19-8.92-13.95-8.92-9.63 0-16.82 7.33-16.82 17.4s7.19 17.25 16.82 17.25c6.76 0 12.65-4.03 14.09-9.49h20.56c-1.58 16.82-16.68 28.9-34.65 28.9C16.32 262.17.5 246.5.5 225.5Zm125.1-35.95v18.41c-2.44-.58-4.89-1.01-7.33-1.01-9.78 0-17.69 5.89-17.69 18.98v34.8H80.31v-70.6h20.27v8.05c4.17-6.04 10.35-9.49 18.26-9.49 2.16 0 4.46.43 6.76.86m69.74 40.69h-52.48c1.44 9.2 8.48 15.1 17.97 15.1 7.91 0 12.94-3.45 14.09-8.34h19.84c-2.3 15.24-15.67 25.16-33.36 25.16-20.85 0-37.1-16.25-37.1-37.1s16.1-36.38 36.67-36.38c19.12 0 34.8 14.95 34.8 34.37 0 1.58-.14 4.89-.43 7.19m-20.71-12.94c-.58-7.19-7.05-12.22-14.52-12.22s-13.8 3.88-16.25 12.22zm101.23-27.18v70.6h-20.27v-10.07c-4.89 7.33-12.51 11.5-22.43 11.5-18.26 0-32.78-16.54-32.78-36.81s14.52-36.67 32.78-36.67c9.92 0 17.54 4.17 22.43 11.5v-10.06h20.27Zm-20.27 35.23c0-9.78-7.76-17.4-17.69-17.4s-17.69 7.76-17.69 17.4 7.91 17.4 17.69 17.4 17.69-7.48 17.69-17.4m59.25-15.96v22.29c0 7.62 5.18 10.93 12.22 10.93 1.73 0 3.59-.14 5.18-.58v18.69c-2.73.72-6.9 1.15-9.78 1.15-16.68 0-27.9-10.93-27.9-29.91v-22.58h-13.09v-19.27h13.09v-18.55h20.27v18.55h17.4v19.27h-17.4Zm48.17-41.27c0 6.76-5.61 12.37-12.37 12.37s-12.37-5.61-12.37-12.37 5.61-12.22 12.37-12.22 12.37 5.61 12.37 12.22m-2.16 22v70.6h-20.27v-70.6zm52.05 70.6h-17.26l-29.33-70.6h22l15.96 42.99 15.67-42.99h22.14l-29.19 70.6Zm98.5-30.48h-52.48c1.44 9.2 8.48 15.1 17.97 15.1 7.91 0 12.94-3.45 14.09-8.34h19.84c-2.3 15.24-15.67 25.16-33.36 25.16-20.85 0-37.1-16.25-37.1-37.1s16.1-36.38 36.67-36.38c19.12 0 34.8 14.95 34.8 34.37 0 1.58-.14 4.89-.43 7.19m-20.71-12.94c-.58-7.19-7.05-12.22-14.52-12.22s-13.8 3.88-16.25 12.22z" />
      <path d="M.96 0v141.68H71.8V70.84h70.84v70.84h70.85V0z" id="d" />
    </svg>
  );
};
