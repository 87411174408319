import type { LinkProps } from 'react-router';

import * as stylex from '@stylexjs/stylex';
import { Link as ReactRouterLink } from 'react-router';

import type { WithoutStyleProps } from '../../types';

import { styles } from './Link.styles';

type Props = WithoutStyleProps<LinkProps> & {
  styles?: stylex.StyleXStyles;
};

export const Link = ({ children, styles: styleOverrides, ...delegated }: Props) => {
  return (
    <ReactRouterLink {...stylex.props(styles.link, styleOverrides)} {...delegated}>
      {children}
    </ReactRouterLink>
  );
};
