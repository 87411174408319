import { useMemo } from 'react';

import { Filters } from '~/components/UI';

import type { TableFiltersProps as Props } from './types';

import { useTableContext } from '../context';
import { TableFiltersContext } from './context';
import { TableFilterTags } from './TableFilterTags';

export const TableFilters = ({ children, options }: Props) => {
  const { getState } = useTableContext();

  return (
    <TableFiltersContext.Provider value={useMemo(() => options, [options])}>
      <Filters show={!!getState().showColumnFilters}>{children}</Filters>

      <TableFilterTags />
    </TableFiltersContext.Provider>
  );
};
