import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormLabel, FormSelect } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { CreativeWork } from '~/types/app';

import { useLineContext } from '../../SectionLines/context';

export const FormActivityType = () => {
  const { control } = useInvoiceQuotationFormContext();
  const { isMobile, isProductLine, lineFieldName } = useLineContext();

  const { t } = useTranslation(['lines', 'validation']);

  return (
    <FormControl control={control} name={`${lineFieldName}.creativeWork`} rules={{ required: true }}>
      {isMobile && <FormLabel>{t('lines:creativeWork.header')}</FormLabel>}

      <FormSelect
        disabled={isProductLine}
        options={([CreativeWork.CREATIVE, CreativeWork.NO_CREATIVE, CreativeWork.REUSAGE, CreativeWork.SUBCONTRACTING] as const).map(
          (creativeWork) => ({
            label: t(`lines:creativeWork.options.${creativeWork}`),
            value: creativeWork,
          }),
        )}
        placeholder={t('lines:creativeWork.placeholder')}
      />

      {isMobile && <FormErrorMessage required={t('validation:required')} />}
    </FormControl>
  );
};
