import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { PageHeader } from '~/components';

import type { UserWithBrackets, UserWithFixedPercentage } from './types';

import { BracketsCommissionInfo } from './BracketsCommissionInfo/BracketsCommissionInfo';
import { styles } from './Pricing.styles';
import { PricingAdvantage } from './PricingAdvantage';
import { PricingFeatures } from './PricingFeatures';
import { PricingInfo } from './PricingInfo';

export const Pricing = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'pricing', 'settings']);

  return (
    <>
      <PageHeader
        description={
          user.commissionSettings.type === 'brackets' ? t('settings:paymentPlan.header.description') : t('pricing:pageHeader.description')
        }
        title={user.commissionSettings.type === 'brackets' ? t('settings:paymentPlan.header.title') : t('pricing:pageHeader.title')}
      />

      {user.commissionSettings.type === 'brackets' ? (
        <BracketsCommissionInfo user={user as UserWithBrackets} />
      ) : (
        <div {...stylex.props(styles.wrapper)}>
          <PricingInfo user={user as UserWithFixedPercentage} />

          <PricingAdvantage />

          <PricingFeatures />
        </div>
      )}
    </>
  );
};
