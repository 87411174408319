import type { User } from '~/types/user';

import { Activity } from '~/types/user';

import { enumValues } from './objects';

// Payment plan

export const getActiveCommissionBracket = (user: User): 0 | 1 | 2 => {
  if (user.commissionSettings.type === 'fixedPercentage') return 0;

  const bracketLimits = [0, ...user.commissionSettings.revenueAmounts] as const;

  return bracketLimits.findLastIndex((bracketLimit) => user.totalInvoicedAmount >= bracketLimit) as 0 | 1 | 2;
};

// Activities

export const isPhotographer = (user: User): boolean => user.activities.some(({ id }) => id === Activity.PHOTOGRAPHY);
export const isWriter = (user: User): boolean => user.activities.some(({ id }) => id === Activity.LANGUAGE_AND_COMMUNICATION);

// Account completion

export enum AccountCompletionStep {
  FINANCIAL_INFO = 'financialInfo',
  VAT_REGULATION = 'vatRegulation',
  FRAMEWORK_AGREEMENT = 'frameworkAgreement',
}

export const getAccountCompletionProgress = (user: User) => ({
  [AccountCompletionStep.FINANCIAL_INFO]: !!user.financial.iban,
  [AccountCompletionStep.VAT_REGULATION]: user.vatExempt !== null,
  [AccountCompletionStep.FRAMEWORK_AGREEMENT]: user.contractSigned && user.addendumSigned,
});

export const isAccountCompleted = (user: User) => enumValues(getAccountCompletionProgress(user)).every((completed) => completed);
