import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { User } from '~/types/user';

import { userQueryOptions, useUser } from '~/api/user';
import { CommandMenuItem } from '~/providers/CommandMenuProvider/CommandMenuItem';

const getDummyInvitorDiscount = (id: number): User['invitorDiscounts'][number] => ({
  id,
  invitationId: id,
  inviteeName: `Uitgenodigde ${id}`,
  isActive: true,
  percentage: 0.25,
  validFrom: `2024-${id.toString().padStart(2, '0')}-01T00:00:00.000000Z`,
  validUntil: `2026-${id.toString().padStart(2, '0')}-31T23:59:59.999999Z`,
});

export const InvitationsCommandMenuItems = () => {
  const { data: user } = useUser();

  const queryClient = useQueryClient();

  const simulateInvitorDiscounts = useCallback(
    (invitorDiscounts: User['invitorDiscounts']) =>
      queryClient.setQueryData<User>(userQueryOptions.queryKey, (user) => (user ? { ...user, invitorDiscounts } : undefined)),
    [queryClient],
  );

  const simulateInviteeDiscounts = useCallback(
    (inviteeDiscount: User['inviteeDiscount']) =>
      queryClient.setQueryData<User>(userQueryOptions.queryKey, (user) => (user ? { ...user, inviteeDiscount } : undefined)),
    [queryClient],
  );

  return (
    <>
      <CommandMenuItem group="Invitations" label="Apply 0 invitor discounts" onClick={() => simulateInvitorDiscounts([])} shortcut="0" />

      <CommandMenuItem
        group="Invitations"
        label="Apply 1 invitor discount"
        onClick={() => simulateInvitorDiscounts([getDummyInvitorDiscount(1)])}
        shortcut="1"
      />

      <CommandMenuItem
        group="Invitations"
        label="Apply 2 invitor discounts"
        onClick={() => simulateInvitorDiscounts([getDummyInvitorDiscount(1), getDummyInvitorDiscount(2)])}
        shortcut="2"
      />

      <CommandMenuItem
        group="Invitations"
        label="Apply 3 invitor discounts"
        onClick={() => simulateInvitorDiscounts([getDummyInvitorDiscount(1), getDummyInvitorDiscount(2), getDummyInvitorDiscount(3)])}
        shortcut="3"
      />

      <CommandMenuItem
        group="Invitations"
        label="Apply 4 invitor discounts"
        onClick={() =>
          simulateInvitorDiscounts([
            getDummyInvitorDiscount(1),
            getDummyInvitorDiscount(2),
            getDummyInvitorDiscount(3),
            getDummyInvitorDiscount(4),
          ])
        }
        shortcut="4"
      />

      <CommandMenuItem
        group="Invitations"
        label={user.inviteeDiscount === null ? 'Apply invitee discount' : 'Remove invitee discount'}
        onClick={() =>
          simulateInviteeDiscounts(
            user.inviteeDiscount === null ? { type: 'ShelterForFree', daysValid: 60, daysActive: 60, referralCode: '60DAGENGRATIS' } : null,
          )
        }
      />
    </>
  );
};
