import type { Split } from '~/types/utils';

import type { DynamicRoutes, DynamicSegments, ReplaceDynamicSegments } from './router.types';

export const routes = {
  login: '/login',
  adminLogin: '/login/admin/:activationKey',
  activate: '/activate/:activationKey',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  register: '/register',
  registerAccountInformation: '/register/1',
  registerBusinessInformation: '/register/2',
  registerCompany: '/register/company',
  registerSuccess: '/register/success',

  dashboard: '/dashboard',

  quotations: '/quotations',
  createQuotation: '/quotations/create/:quotationId',
  editQuotation: '/quotations/:quotationId/edit',
  clientQuotationView: '/quotations/:quotationAllowKey/client',

  invoices: '/invoices',
  showInvoice: '/invoices/:invoiceId',
  createInvoice: '/invoices/create/:invoiceId',
  editInvoice: '/invoices/:invoiceId/edit',
  createCreditnote: '/invoices/creditnote/create',

  products: '/products',
  createProduct: '/products/create',
  editProduct: '/products/:productId/edit',

  clients: '/clients',
  showClient: '/clients/:clientId',
  createClient: '/clients/create',
  createClientOrganisationStep: '/clients/create/organisation',
  createClientContactStep: '/clients/create/contact',
  createClientPrivateStep: '/clients/create/private',
  editClient: '/clients/:clientId/edit',
  createContact: '/clients/:clientId/contacts/create',
  editContact: '/clients/:clientId/contacts/:contactId',

  community: '/community',

  settingsProfile: '/settings/profile',
  settingsGeneral: '/settings/general',
  settingsInvitations: '/settings/invitations',
  settingsDocuments: '/settings/documents',
  settingsIntegrations: '/settings/integrations',
  settingsPricing: '/settings/pricing',

  oauthBillit: '/oauth2/callback/billit',

  uiToolkit: '/developer/UI-toolkit',
  designSystem: '/design-system',
} as const satisfies Record<string, `/${string}`>;

/** Replace dynamic parts of a route by concrete values. */
export const r = <T extends DynamicRoutes[keyof DynamicRoutes], const U extends Record<DynamicSegments<T>, string | number>>(
  route: T,
  replacements: U,
) =>
  (route.split('/') as Split<T, '/'>)
    .map((segment) => (segment.startsWith(':') ? replacements[segment.slice(1) as keyof U] : segment))
    .join('/') as ReplaceDynamicSegments<T, U>;
