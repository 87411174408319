import * as stylex from '@stylexjs/stylex';

import { neutralColors } from '~/styles/tokens.stylex';

export const buttonVariables = stylex.defineVars({
  colorDark: neutralColors.gray800,
  colorLight: neutralColors.white,
  colorDisabledDark: neutralColors.gray400,
  colorDisabledLight: neutralColors.gray050,
  outlineColor: neutralColors.gray800,
  outlineOffset: '2px',
});

export const buttonDarkBackgroundTheme = stylex.createTheme(buttonVariables, {
  outlineColor: neutralColors.white,
});
