import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconTooltip } from '~/components';
import { useIntl } from '~/hooks/useIntl';
import { getActiveCommissionBracket } from '~/utils/user';

import type { UserWithBrackets } from '../types';

import styles from './BracketsCommissionProgress.module.scss';

const PROGRESS_BAR_MAX = 60_000;

type Props = {
  user: UserWithBrackets;
};

export const BracketsCommissionProgress = ({ user }: Props) => {
  const { formatCurrency, formatCurrencyShort, formatDateShort, formatDecimal } = useIntl();
  const { t } = useTranslation(['settings']);

  const activeBracket = getActiveCommissionBracket(user);

  return (
    <div className={styles.Wrapper}>
      <div>
        <p className={styles.Title}>
          <span>{t('settings:paymentPlan.progress.title')}</span>
          <IconTooltip inline={false} text={t('settings:paymentPlan.progress.tooltip')} />
        </p>
        <p className={styles.Amount}>
          {user.totalInvoicedAmount > 0 ? formatCurrency(user.totalInvoicedAmount) : t('settings:paymentPlan.progress.zeroProgress')}
        </p>
      </div>

      <div className={styles.Separator} />

      <div className={styles.ProgressBar}>
        <div className={styles.Percentages}>
          {([0, 1, 2] as const).map((bracket) => (
            <span
              className={classNames(styles.Percentage, bracket === 1 && styles.Middle, activeBracket === bracket && styles.Active)}
              key={bracket}
            >
              {formatDecimal(user.commissionSettings.creativePercentages[bracket])}%
            </span>
          ))}
        </div>

        <div className={styles.Bar}>
          <div className={styles.BarBackground} />
          <div className={styles.BarFilled} style={{ width: `${Math.min((user.totalInvoicedAmount / PROGRESS_BAR_MAX) * 100, 100)}%` }} />

          <div className={classNames(styles.Milestone, styles.Milestone__First, activeBracket > 1 && styles.Active)} />
          <div className={classNames(styles.Milestone, styles.Milestone__Second, activeBracket > 2 && styles.Active)} />
        </div>

        <div className={styles.RevenueAmounts}>
          <span className={styles.RevenueAmount}>{formatCurrencyShort(user.commissionSettings.revenueAmounts[0])}</span>
          <span className={styles.RevenueAmount}>{formatCurrencyShort(user.commissionSettings.revenueAmounts[1])}</span>
        </div>
      </div>

      <div className={styles.Separator} />

      <div className={styles.Disclaimer}>
        {t('settings:paymentPlan.progress.disclaimer', { startDate: formatDateShort(user.commissionHistoryStart ?? new Date()) })}
      </div>
    </div>
  );
};
