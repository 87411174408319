import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { animationSpeed, brandColors, fontSize, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

const outlineFadeIn = stylex.keyframes({
  '0%': {
    outlineColor: 'transparent',
    outlineOffset: '4px',
  },
  '100%': {
    outlineColor: 'currentColor',
    outlineOffset: '2px',
  },
});

export const styles = stylex.create({
  footer: {
    gridArea: 'footer',
    marginInline: {
      default: spacing.xxlarge,
      [TabletAndBelow]: spacing.none,
    },
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.xxsmall,
    justifyContent: 'space-between',
    alignItems: 'center',
    color: systemColors.textMuted,
    fontSize: fontSize.tiny,
  },

  socialsList: {
    display: 'flex',
    gap: spacing.xsmall,
  },

  socialLink: {
    position: 'relative',
    color: {
      default: systemColors.textMuted,
      ':hover': brandColors.primary400,
      ':focus-visible': brandColors.primary400,
    },
    borderRadius: radius.xxxsmall, // Rounding of the outline
    outlineStyle: {
      default: 'none',
      ':focus-visible': 'solid',
    },
    outlineWidth: '1px',
    outlineOffset: '2px',
    outlineColor: 'currentColor',
    animationName: {
      default: null,
      ':focus-visible': outlineFadeIn,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
    transitionProperty: 'color',
    transitionDuration: animationSpeed.fast,

    // Increased hitbox
    '::after': {
      content: '',
      position: 'absolute',
      inset: -6,
    },
  },
});
