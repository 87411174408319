import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsibleSection } from '~/components';

import { CreateCustomConditionModal } from './CreateCustomConditionModal/CreateCustomConditionModal';
import { CustomConditionsTable } from './CustomConditionsTable';

export const CustomConditions = () => {
  const { t } = useTranslation(['common', 'settings']);

  const [showWizard, setShowWizard] = useState(false);

  return (
    <>
      <CollapsibleSection.Button onClick={() => setShowWizard(true)}>
        {t('settings:general.customConditions.create')}
      </CollapsibleSection.Button>

      <CustomConditionsTable />

      {showWizard && <CreateCustomConditionModal onClose={() => setShowWizard(false)} />}
    </>
  );
};
