export const products = {
  alerts: {
    successCreated: 'Your product was successfully sent for approval!',
    successDeleted: 'The product was successfully removed!',
    successUpdated: 'The product has been successfully modified!',
  },
  announcement:
    'Products, a performance line that you can add over and over again. Something you often invoice that you no longer have to fill in from scratch and that we have to check and approve. More information <a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">can be found here.</a>',
  createEdit: {
    blocker: {
      create: 'Are you sure you want to leave the page? Your product has not been created yet.',
      edit: 'Are you sure you want to leave the page? Your changes are only saved when you create the product.',
    },
    confirmation: {
      create: {
        description: 'Create your product, so we can check and approve it, and you can invoice easily!',
        submit: 'Create product',
        title: 'Your product is being created',
      },
      edit: {
        description: 'Are you sure you want to edit the product? When modifying your product, it must be approved again.',
        submit: 'Edit product',
        title: 'Your product is being edited',
      },
    },
    info: {
      description: 'Enter your product information here',
      title: 'Product information',
    },
    submit: {
      create: 'Create product',
      edit: 'Edit product',
    },
    title: {
      create: 'Product details',
      edit: 'Edit product',
    },
  },
  fields: {
    activityType: {
      label: 'Type of performance',
      placeholder: 'Type of performance',
      tooltip:
        'Select which type of performance applies. <a href="https://knowledge.creativeshelter.be/wanneer-moet-je-welke-soort-prestatie-aanduiden">You can consult here which performance applies when.</a>',
    },
    categories: {
      label: 'Categories',
      placeholder: 'Add a category here, such as: editing, post-production, translation, final editing, ...',
    },
    name: {
      label: 'Product name',
      placeholder: 'Product name',
    },
    price: {
      label: 'Price (excl. VAT)',
      tooltip:
        'Do you often use the same price for your product? Fill it out already, save yourself some work. You can still change it when you prepare your invoice.',
    },
    unit: {
      label: 'Unit',
    },
  },
  header: {
    actionBlock: {
      button: 'Create product',
    },
    description: 'Check out your products and services you offer and edit them here.',
    title: 'Products',
  },
  modal: {
    add_one: '{{count}} add product',
    add_other: '{{count}} add products',
    add_zero: 'Add product(s)',
    createNew: 'Create new product',
    missingTranslations: 'No translation found',
    title: 'Add product(s)',
  },
  overview: {
    columns: {
      creativeWork: 'Type',
      name: 'Product name',
      price: 'Price',
      productCategories: 'Categories',
      status: {
        options: {
          Approved: 'Approved',
          InReview: 'For approval',
          Pending: 'For approval',
          Rejected: 'Refused',
        },
        title: 'Status',
      },
    },
    deleteModal: {
      description: 'Are you sure you want to remove this product?',
      title: 'Delete product',
    },
  },
};
