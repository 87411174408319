import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Warning } from '~/components';
import { Icon } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';
import { routes } from '~/providers/RouterProvider/router.routes';

import type { UserWithBrackets } from '../types';

import styles from './BracketsCommissionInfo.module.scss';
import { BracketsCommissionProgress } from './BracketsCommissionProgress';
import { CurrentBracketsCommission } from './CurrentBracketsCommission';

type Props = {
  user: UserWithBrackets;
};

export const BracketsCommissionInfo = ({ user }: Props) => {
  const { formatDate } = useIntl();
  const { t } = useTranslation(['settings']);

  const hasFixedCreativePercentage =
    user.commissionSettings.creativePercentages[0] === user.commissionSettings.creativePercentages[1] &&
    user.commissionSettings.creativePercentages[1] === user.commissionSettings.creativePercentages[2];

  const activeFreeTrial = user.inviteeDiscount?.type === 'ShelterForFree';

  return (
    <div className={classNames(styles.CommissionInfo, hasFixedCreativePercentage && styles.SingleColumn)}>
      <div className={styles.Warning}>
        <Warning title={t('settings:paymentPlan.bracketWarning.title')}>
          {t('settings:paymentPlan.bracketWarning.description', {
            endOfCommissionYear: formatDate(dayjs(user.commissionHistoryStart).year(2025).toDate()),
          })}
        </Warning>
      </div>

      <CurrentBracketsCommission user={user} />

      {!hasFixedCreativePercentage && <BracketsCommissionProgress user={user} />}

      <div className={styles.InvitationCTA}>
        <div className={styles.InvitationCTA__Icon}>
          <Icon name="Sell" />
        </div>

        <p className={styles.InvitationCTA__Text}>
          <span>
            {activeFreeTrial
              ? t('settings:paymentPlan.freeInvitationCTA.text', { count: user.inviteeDiscount?.daysActive })
              : t('settings:paymentPlan.invitationCTA.text')}
          </span>
          {!activeFreeTrial && <Link to={routes.settingsInvitations}> {t('settings:paymentPlan.invitationCTA.link')}</Link>}
        </p>
      </div>
    </div>
  );
};
