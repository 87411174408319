import type { DropdownButtonProps as Props } from './types';

import { Button } from '../Button/Button';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import styles from './DropdownButton.module.scss';

export const DropdownButton = ({ items, text, ...props }: Props) => {
  return (
    <div className={styles['dropdown-button-wrapper']}>
      <Button {...props} hasDropdown type="primary">
        {text}
      </Button>

      <DropdownMenu
        buttonType="primary"
        dropdownClass={styles.dropdown}
        extraClasses={styles.menu}
        iconName="KeyboardArrowDown"
        iconSize={24}
        items={items}
      />
    </div>
  );
};
