import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { Link } from 'react-router';

import { useUser } from '~/api/user';
import { Icon } from '~/components/SVG';
import { Button } from '~/components/UI';

import { useSettingsMenuItems } from '../hooks';
import { styles } from './TopBarMenu.styles';

export const TopBarMenu = () => {
  const { data: user } = useUser();

  const settingsMenuItems = useSettingsMenuItems();

  return (
    <Menu>
      <MenuButton as={Button} size="narrow" variant="tertiary">
        {({ open }) => (
          <div {...stylex.props(styles.buttonContent)}>
            {user.firstName}
            <Icon name="KeyboardArrowDown" styles={[styles.icon, open && styles.iconOpen]} />
          </div>
        )}
      </MenuButton>

      <MenuItems {...stylex.props(styles.itemsContainer)} anchor={{ to: 'bottom end', gap: 4 }} modal={false} transition>
        {settingsMenuItems.map((item) =>
          item.route !== undefined ? (
            <MenuItem {...stylex.props(styles.item)} as={Link} key={item.text} to={item.route}>
              <Icon name={item.iconName} />
              {item.text}
            </MenuItem>
          ) : (
            <MenuItem {...stylex.props(styles.item)} as="button" key={item.text} onClick={item.onClick}>
              <Icon name={item.iconName} />
              {item.text}
            </MenuItem>
          ),
        )}
      </MenuItems>
    </Menu>
  );
};
